import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { VersionUpdateComponent } from './version-update/version-update.component'
import { VersionUpdateDialogComponent } from './version-update-dialog/version-update-dialog.component'
import { TranslateModule } from '@ngx-translate/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'

@NgModule({
  declarations: [VersionUpdateComponent, VersionUpdateDialogComponent],
  exports: [VersionUpdateComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de'
    }),
    MatDialogModule,
    MatButtonModule
  ]
})
export class UpdateModule {}
