<app-ws-email-element-wrapper
  *ngIf="element"
  [element]="element"
  [(elementIsActive)]="elementIsActive"
  [parent]="parent"
  [dragHandle]="dragHandle"
  [template]="template"
  (elementIsActiveChange)="elementIsActiveChange($event)"
  [component]="this"
>
  @if (element) {
    <table *ngIf="elementIsActive" [width]="element.styles['width']">
      <td [ngStyle]="element.getStyles(template.testData).td" valign="middle" align="center" style="padding: 0">
        <span [ngStyle]="template.getStyleOfAttributes()">
          <ws-form-text-editor-new
            [(content)]="element.content"
            style="
              --ngx-editor-border-color: transparent;
              --ws-form-field-padding-top: 0;
              --ws-form-text-editor-content-padding: 0px;
              --ws-form-text-editor-border-color: transparent;
              --ws-form-text-editor-content-p-margin: 0;
            "
            [showTextColorPicker]="false"
            [focusEditorAfterViewInit]="true"
            [useMenu]="true"
            [useFloatingMenu]="true"
            [pasteWithoutFormatting]="true"
            [placeholderVariables]="editorStateService.allPlaceholderVariables"
            [ngStyle]="element.getStyles(template.testData).content"
            [toolbar]="toolbar"
          ></ws-form-text-editor-new>
        </span>
      </td>
    </table>
    @if (!elementIsActive) {
      <span
        class="ws-email-button-element"
        [innerHTML]="
          element.getHtmlWithGlobalStyles(template.styles, template.testData) | safeHtml: { removeHref: true }
        "
      ></span>
    }
  }
</app-ws-email-element-wrapper>
