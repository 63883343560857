import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FieldComponent } from '../field/field.component'
import { Editor, schema, Toolbar } from 'ngx-editor'
import { CustomFloatingMenuComponent } from './custom-floating-menu/custom-floating-menu.component'
import { WsEmailPlaceholderVariable } from '../../../../../../email/src/app/data/types/ws-email-placeholder-variable'

@Component({
  selector: 'ws-form-text-editor-new',
  templateUrl: './text-editor-new.component.html',
  styleUrls: ['./text-editor-new.component.scss']
})
export class TextEditorNewComponent extends FieldComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() useMenu = true
  @Input() useFloatingMenu = false
  @Input() showEmojiPicker = true
  @Input() showTextColorPicker = true
  @Input() focusEditorAfterViewInit = false
  @Input() content = ''
  @Input() toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['blockquote', 'link'],
    ['code', 'ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    // or, set options for link:
    //[{ link: { showOpenInNewTab: false } }, 'image'],
    ['background_color', 'align_left', 'align_center', 'align_right', 'align_justify'],
    ['horizontal_rule', 'format_clear', 'indent', 'outdent'],
    ['superscript', 'subscript'],
    ['undo', 'redo']
  ]

  @Input() pasteWithoutFormatting = false
  @Input() placeholderVariables: WsEmailPlaceholderVariable[] = []

  editor: Editor | undefined

  @ViewChild('floatingMenu', { static: false }) floatingMenu: CustomFloatingMenuComponent | undefined

  @Output() contentChange = new EventEmitter<string>()
  @Output() focusIn = new EventEmitter<void>()
  @Output() focusOut = new EventEmitter<void>()

  ngOnInit() {
    this.editor = new Editor({
      content: this.content,
      history: true,
      keyboardShortcuts: true,
      inputRules: true,
      plugins: [], //https://prosemirror.net/docs/guide/#state
      schema, //https://prosemirror.net/examples/schema/
      nodeViews: {}, //https://prosemirror.net/docs/guide/#state,
      attributes: {}, // https://prosemirror.net/docs/ref/#view.EditorProps.attributes
      linkValidationPattern: '',
      parseOptions: {} // https://prosemirror.net/docs/ref/#model.ParseOptions
    })
  }

  override ngAfterViewInit() {
    if (this.focusEditorAfterViewInit) {
      this.focusEditor()
    }
    if (this.pasteWithoutFormatting) {
      this.addInsertWithoutFormattingEventHandler()
    }
    super.ngAfterViewInit()
  }

  onContentChange() {
    this.contentChange.emit(this.content || '')
  }

  focusEditor() {
    this.editor?.view.focus()
  }

  /**
   * Add event handler for paste event to insert text without formatting
   * @private
   */
  private addInsertWithoutFormattingEventHandler() {
    const editorElement = document.querySelector('.ws-text-editor_editor')
    if (editorElement) {
      editorElement.addEventListener(
        'paste',
        (event) => {
          const clipboardEvent = event as ClipboardEvent
          this.handlePaste(clipboardEvent)
        },
        { capture: true }
      )
    }
  }

  /**
   * Handle paste event and insert text without formatting
   * @param clipboardEvent
   * @private
   */
  private handlePaste(clipboardEvent: ClipboardEvent) {
    clipboardEvent.preventDefault()
    clipboardEvent.stopPropagation()

    const pasteData = clipboardEvent?.clipboardData?.getData('text')
    if (!pasteData) return

    const selection = window.getSelection()
    if (!selection?.rangeCount) return

    selection.deleteFromDocument()
    selection.getRangeAt(0).insertNode(document.createTextNode(pasteData))
    selection.collapseToEnd()
  }

  override ngOnDestroy() {
    this.editor?.destroy()
    super.ngOnDestroy()
  }
}
