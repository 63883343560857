import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NodeSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { Subscription } from 'rxjs'
import { Editor, Toolbar, TOOLBAR_FULL } from 'ngx-editor'
import { CustomEditorMenuComponent } from '../custom-editor-menu/custom-editor-menu.component'
import { WsEmailPlaceholderVariable } from '../../../../../../../email/src/app/data/types/ws-email-placeholder-variable'

@Component({
  selector: 'ws-form-text-editor-floating-menu',
  templateUrl: './custom-floating-menu.component.html',
  styleUrls: ['./custom-floating-menu.component.scss']
})
export class CustomFloatingMenuComponent implements OnInit, OnDestroy {
  @Input() autoPlace = false
  @Input() showEmojiPicker = true
  @Input() showTextColorPicker = true
  @Input() editor: Editor | undefined
  @Input() toolbar: Toolbar = TOOLBAR_FULL
  @Input() placeholderVariables: WsEmailPlaceholderVariable[] = []

  private showMenu = false

  private update$ = new Subscription()

  @ViewChild('customEditorMenu') customEditorMenu: CustomEditorMenuComponent | undefined

  ngOnInit() {
    this.updateSubscriptions()
  }

  hide() {
    this.showMenu = false
  }

  show() {
    this.showMenu = true
  }

  private canShowMenu(view: EditorView): boolean {
    const { state } = view
    const { selection } = state

    if (selection instanceof NodeSelection) {
      if (selection.node.type.name === 'image') {
        return false
      }
    }

    return true
  }

  private update(view: EditorView) {
    const canShowMenu: boolean = this.canShowMenu(view)

    if (!canShowMenu) {
      this.hide()
      return
    }

    this.show()
  }

  updateSubscriptions() {
    if (!this.editor) {
      return
    }
    this.update$ = this.editor.update.subscribe((view: EditorView) => {
      this.update(view)
    })
  }

  ngOnDestroy() {
    this.update$.unsubscribe()
  }

  @HostBinding('style') get display(): Partial<CSSStyleDeclaration> {
    return {
      visibility: this.showMenu ? 'visible' : 'hidden',
      opacity: this.showMenu ? '1' : '0'
    }
  }
}
