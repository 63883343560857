import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { WsEmailElementComponent } from '../ws-email-element/ws-email-element.component'
import { Toolbar } from 'ngx-editor'

@Component({
  selector: 'app-ws-email-text',
  templateUrl: './ws-email-text.component.html',
  styleUrls: ['./ws-email-text.component.scss', '../ws-email-element/ws-email-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsEmailTextComponent extends WsEmailElementComponent implements OnInit, AfterViewInit {
  @ViewChild('innerTextContainer') innerTextContainer!: ElementRef

  @Input() toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['link'],
    ['ordered_list', 'bullet_list'],
    ['background_color', 'align_left', 'align_center', 'align_right', 'align_justify'],
    ['horizontal_rule', 'format_clear', 'indent', 'outdent'],
    ['superscript', 'subscript'],
    ['undo', 'redo']
  ]

  // reduced toolbar used for headings
  @Input() reducedToolbar: Toolbar = [
    ['bold', 'italic'],
    ['link'],
    ['background_color', 'align_left', 'align_center', 'align_right', 'align_justify'],
    ['format_clear']
  ]

  override ngAfterViewInit() {
    super.ngAfterViewInit()
  }

  override ngOnInit() {
    super.ngOnInit()
  }

  override elementIsActiveChange(event: boolean) {
    this.convertHtmlEditorLineBreaks(event)
    this.cd.detectChanges()
  }

  private convertHtmlEditorLineBreaks(elementIsActive: boolean) {
    if (this.element) {
      if (!elementIsActive) {
        this.element.content = this.element.content
          .replace(/<br>/g, '<br class="preserve-line-break">')
          .replace(/<p>\s*<\/p>/g, '<br>')
        /*
          Todo: Check possibility to use color/test data replacement
          this.element.content = this.template.replaceValueWithTestDataIdentifier(
          this.element.content,
          this.template.testData
          )
        */
      } else {
        this.element.content = this.element.content.replace(/<br>/g, '<p></p>')
        // Todo: Check possibility to use color/test data replacement
        // this.element.content = this.template.replaceTestData(this.element.content, this.template.testData)
      }
      this.element.content = this.element.content.replace(/(<img[^>]+)(?<!\/)>/gi, '$1/>')
    }
  }
}
