@if (selectedElement) {
  <div [ngClass]="{ 'p-4': !isSubElement }" class="ws-email-element-settings">
    @if (
      selectedElement.type === 'Section' &&
      selectedElement.getVisibleChildren().length &&
      selectedElement.getVisibleChildren()[0].type === 'Column'
    ) {
      <mat-expansion-panel
        hideToggle
        [expanded]="showSelectedSubElement"
        (opened)="showSelectedSubElement = true"
        (closed)="showSelectedSubElement = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="flex justify-between items-center w-full">
            <h1 class="element-settings-title-big">
              {{ 'element.type.columns' | translate }} {{ 'template.settings.settings' | translate }}
            </h1>
            <ws-icon [ngClass]="{ 'rotate-90': showSelectedSubElement }">chevron_right</ws-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>

        @if (selectedElement.getVisibleChildren().length > 1) {
          <div class="element-settings__heading-button-container w-full flex items-center pt-2 mb-4">
            @for (column of selectedElement.getVisibleChildren(); track index; let index = $index) {
              <button
                class="w-full shadow-md m-1"
                [ngClass]="{ 'element-settings__selected-headline': index === selectedSubElementIndex }"
                (click)="selectedSubElementIndex = index"
                mat-button
              >
                {{ 'element.type.column' | translate }} {{ index + 1 }}
              </button>
            }
          </div>
        }
        <app-ws-email-element-settings
          [isSubElement]="true"
          [selectedElement]="selectedElement.getVisibleChildren()[selectedSubElementIndex]"
          (selectedElementPropertyChange)="handleSubElementPropertyChange()"
        ></app-ws-email-element-settings>
      </mat-expansion-panel>
    }
    @if (selectedElement.type !== 'Section') {
      <ng-container *ngTemplateOutlet="elementSettings"></ng-container>
    } @else {
      <mat-expansion-panel
        hideToggle
        [expanded]="showSelectedElement"
        (opened)="showSelectedElement = true"
        (closed)="showSelectedElement = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="flex justify-between items-center w-full">
            <h1 class="element-settings-title-big">
              {{ 'element.type.sections' | translate }} {{ 'template.settings.settings' | translate }}
            </h1>
            <ws-icon [ngClass]="{ 'rotate-90': showSelectedElement }">chevron_right</ws-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="elementSettings"></ng-container>
      </mat-expansion-panel>
    }

    <ng-template #elementSettings>
      @if (formIsInitialized) {
        <form [formGroup]="elementSettingsForm">
          @if (fieldExists('name')) {
            <ws-form-select
              style="--ws-form-field-padding-top: 2em"
              label="Icon"
              formControlName="name"
              [options]="socialPlatformOptions"
            ></ws-form-select>
          }
          @if (fieldExists('icon-size')) {
            <div class="mb-4">
              <h1 class="element-settings-title">{{ 'template.element.icons-size' | translate }}</h1>
              <ws-form-input
                style="--ws-form-field-padding-top: 2em"
                [min]="0"
                suffix="px"
                label="{{ 'template.element.size' | translate }}"
                formControlName="icon-size"
                type="number"
              ></ws-form-input>
            </div>
          }
          @if (atLeastOneFieldExists(['background-url', 'background-color'])) {
            <div class="mb-4">
              <div class="flex justify-start items-center">
                <h1 class="element-settings-title">
                  {{ 'template.element.background' | translate }}
                </h1>
                @if (selectedElement.styles['background-color'] || selectedElement.styles['background-url']) {
                  <ws-icon (click)="resetFields(['background-color', 'background-url'])">close_small</ws-icon>
                }
              </div>
              @if (fieldExists('background-url')) {
                <ws-form-input
                  formControlName="background-url"
                  label="{{ 'template.element.backgroundImageUrl' | translate }}"
                  style="--ws-form-field-padding-top: 2em"
                ></ws-form-input>
              }
              @if (fieldExists('background-color')) {
                <div class="flex justify-between">
                  <ws-form-input
                    style="--ws-form-field-padding-top: 2em; --ws-form-field-width: 200px"
                    label="{{ 'template.element.backgroundColor' | translate }}"
                    formControlName="background-color"
                  >
                  </ws-form-input>
                  <div class="flex items-center justify-between w-full ml-4">
                    <ws-icon
                      [matTooltip]="'ws.forms.textEditor.tooltip.selectPlaceholderVariable' | translate"
                      (click)="openInsertPlaceholderDialogColorPicker('background-color')"
                      class="cursor-pointer pt-8"
                      >data_object</ws-icon
                    >
                    <ws-form-color-picker
                      style="--ws-form-field-padding-top: 2em"
                      [compact]="true"
                      formControlName="background-color"
                    >
                    </ws-form-color-picker>
                  </div>
                </div>
              }
            </div>
          }
          @if (fieldExists('alt')) {
            <div class="mb-4">
              <ws-form-input
                formControlName="alt"
                label="{{ 'template.element.imageAltText' | translate }}"
                style="--ws-form-field-padding-top: 2em"
              ></ws-form-input>
            </div>
          }
          @if (fieldExists('full-width')) {
            <div class="mb-4">
              <h1 class="element-settings-title">{{ 'template.element.size' | translate }}</h1>
              <ws-form-toggle
                (click)="setFullWithSection()"
                formControlName="full-width"
                label="{{ 'template.element.fullWidth' | translate }}"
              ></ws-form-toggle>
            </div>
          }
          @if (fieldExists('columns')) {
            <div class="mb-4 element-columns">
              <h1 class="element-settings-title">{{ 'Spalten' | translate }}</h1>
              <app-ws-email-column-grid
                [selectedRow]="selectedElement"
                (selectedRowChange)="handleSelectedRowChange($event)"
              >
              </app-ws-email-column-grid>
            </div>
          }
          @if (
            fieldExists('src') &&
            (selectedElement.type !== 'SocialElement' || selectedElement.styles['name'] === 'custom')
          ) {
            <div class="mb-4">
              <h1 class="element-settings-title">{{ 'template.image.source' | translate }}</h1>
              <ws-button
                buttonClass="w-full justify-center"
                class="mt-4 bg-accent block text-white w-full"
                type="button"
                (click)="showImageSelectDialog = true"
                >{{ 'template.image.select' | translate }}
              </ws-button>
              <ws-form-input
                class="element-settings__image-url-input"
                label="{{ 'template.element.imageUrl' | translate }}"
                formControlName="src"
              ></ws-form-input>
              <app-ws-email-select-image-dialog
                [currentImageSrc]="this.elementSettingsForm.get('src')?.value || ''"
                [(showDialog)]="showImageSelectDialog"
                (imageSelected)="updateFormValue('src', $event)"
              ></app-ws-email-select-image-dialog>
            </div>
          }
          @if (fieldExists('href')) {
            <div class="mb-4">
              <h1 class="element-settings-title">{{ 'template.element.url' | translate }}</h1>
              <div class="flex items-center justify-between w-full">
                <ws-form-input
                  formControlName="href"
                  class="w-full"
                  style="--ws-form-field-padding-top: 2em"
                  label="{{ 'template.element.url' | translate }}"
                ></ws-form-input>
                <ws-icon
                  [matTooltip]="'ws.forms.textEditor.tooltip.selectPlaceholderVariable' | translate"
                  (click)="openInsertPlaceholderDialogUrl('href')"
                  class="cursor-pointer pt-8 ml-2"
                  >data_object</ws-icon
                >
              </div>
            </div>
          }
          @if (atLeastOneFieldExists(['width', 'height'])) {
            <div class="mb-4" id="element-settings-width">
              <h1 class="element-settings-title">{{ 'template.element.size' | translate }}</h1>
              <div [ngClass]="{ 'grid-cols-2': !['Image'].includes(selectedElement.type) }" class="grid gap-x-16">
                @if (fieldExists('width')) {
                  @if (['Image'].includes(selectedElement.type)) {
                    <ws-form-slider
                      [min]="5"
                      label="{{ 'template.element.width' | translate }}"
                      formControlName="width"
                    >
                    </ws-form-slider>
                  } @else {
                    <ws-form-input
                      [min]="0"
                      [max]="widthSuffix === '%' ? 100 : undefined"
                      [suffix]="widthSuffix"
                      style="--ws-form-field-padding-top: 2em"
                      label="{{ 'template.element.width' | translate }}"
                      formControlName="width"
                      type="number"
                    ></ws-form-input>
                  }
                }
                @if (fieldExists('height') && !['Image'].includes(selectedElement.type)) {
                  <ws-form-input
                    style="--ws-form-field-padding-top: 2em"
                    [min]="0"
                    suffix="px"
                    label="{{ 'template.element.height' | translate }}"
                    formControlName="height"
                    type="number"
                  ></ws-form-input>
                }
              </div>
              @if (fieldExists('widthSuffix')) {
                <div class="flex justify-start items-center col-span-2 mt-2">
                  <span>px</span>
                  <ws-form-toggle
                    (click)="onWidthSuffixChange()"
                    class="mx-2"
                    formControlName="widthSuffix"
                  ></ws-form-toggle>
                  <span>%</span>
                  <span class="ml-5 text-sm">{{ 'template.element.widthUnitSelection' | translate }}</span>
                </div>
              }
            </div>
          }
          @if (atLeastOneFieldExists(['border', 'border-width', 'border-color', 'border-style'])) {
            <div class="ws-email-border-settings mb-4">
              <div class="flex justify-between items-center">
                <div class="flex items-center">
                  <h1 class="element-settings-title">
                    @if (selectedElement.type === 'Divider') {
                      {{ 'element.type.divider' | translate }}
                    } @else {
                      {{ 'template.element.border' | translate }}
                    }
                  </h1>
                  @if (selectedElement.styles['border'] && selectedElement.styles['border'] !== 'none') {
                    <ws-icon (click)="resetBorder(true)">close_small</ws-icon>
                  }
                  @if (
                    selectedElement.styles['border-top'] ||
                    selectedElement.styles['border-right'] ||
                    selectedElement.styles['border-bottom'] ||
                    selectedElement.styles['border-left']
                  ) {
                    <ws-icon (click)="resetAdvancedBorder(true)">close_small</ws-icon>
                  }
                </div>
                @if (fieldExists('showBorderAdvancedSettings')) {
                  <ws-form-toggle
                    formControlName="showBorderAdvancedSettings"
                    label="{{ 'template.element.advancedOptions' | translate }}"
                    (click)="borderAdvancedSettingsToggle()"
                  ></ws-form-toggle>
                }
              </div>
              @if (
                !fieldExists('showBorderAdvancedSettings') ||
                !elementSettingsForm.get('showBorderAdvancedSettings')?.value
              ) {
                <div class="grid grid-cols-2 gap-x-8">
                  @if (fieldExists('showBorderAdvancedSettings')) {
                    <span class="col-span-2 text-sm">{{ 'template.element.allSides' | translate }}</span>
                  }
                  @if (fieldExists('border-width')) {
                    <ws-form-input
                      formControlName="border-width"
                      label="{{ 'template.element.size' | translate }}"
                      style="--ws-form-field-padding-top: 2em"
                      [min]="0"
                      suffix="px"
                      type="number"
                    ></ws-form-input>
                  }
                  @if (fieldExists('border-style')) {
                    <ws-form-select
                      [options]="borderStyleOptions"
                      formControlName="border-style"
                      label="{{ 'template.element.style' | translate }}"
                      style="--ws-form-field-padding-top: 2em"
                    ></ws-form-select>
                  }
                </div>
                @if (fieldExists('border-color')) {
                  <div class="flex justify-between mb-4">
                    <ws-form-input
                      style="--ws-form-field-padding-top: 2em; --ws-form-field-width: 200px"
                      label="{{ 'template.element.borderColor' | translate }}"
                      formControlName="border-color"
                    >
                    </ws-form-input>
                    <div class="flex items-center justify-between w-full ml-4">
                      <ws-icon
                        [matTooltip]="'ws.forms.textEditor.tooltip.selectPlaceholderVariable' | translate"
                        (click)="openInsertPlaceholderDialogColorPicker('border-color')"
                        class="cursor-pointer pt-8"
                        >data_object</ws-icon
                      >
                      <ws-form-color-picker
                        style="--ws-form-field-padding-top: 2em"
                        [compact]="true"
                        formControlName="border-color"
                      >
                      </ws-form-color-picker>
                    </div>
                  </div>
                }
              } @else {
                @for (direction of ['top', 'bottom', 'left', 'right']; track direction) {
                  <div class="grid grid-cols-2 gap-x-8">
                    <div class="flex items-center col-span-2">
                      <span class="text-sm">{{ 'template.element.' + direction | translate }}</span>
                      @if (selectedElement.styles['border-' + direction]) {
                        <ws-icon (click)="resetAdvancedBorder(true, direction)">close_small</ws-icon>
                      }
                    </div>
                    @if (fieldExists('border-width')) {
                      <ws-form-input
                        formControlName="border-width-{{ direction }}"
                        label="{{ 'template.element.size' | translate }}"
                        style="--ws-form-field-padding-top: 2em"
                        [min]="0"
                        suffix="px"
                        type="number"
                      ></ws-form-input>
                    }
                    @if (fieldExists('border-style')) {
                      <ws-form-select
                        [options]="borderStyleOptions"
                        formControlName="border-style-{{ direction }}"
                        label="{{ 'template.element.style' | translate }}"
                        style="--ws-form-field-padding-top: 2em"
                      ></ws-form-select>
                    }
                  </div>
                  @if (fieldExists('border-color')) {
                    <div class="flex justify-between mb-4">
                      <ws-form-input
                        style="--ws-form-field-padding-top: 2em; --ws-form-field-width: 200px"
                        label="{{ 'template.element.borderColor' | translate }}"
                        formControlName="border-color-{{ direction }}"
                      >
                      </ws-form-input>
                      <div class="flex items-center justify-between w-full ml-4">
                        <ws-icon
                          [matTooltip]="'ws.forms.textEditor.tooltip.selectPlaceholderVariable' | translate"
                          (click)="openInsertPlaceholderDialogColorPicker('border-color-' + direction)"
                          class="cursor-pointer pt-8"
                          >data_object</ws-icon
                        >
                        <ws-form-color-picker
                          style="--ws-form-field-padding-top: 2em"
                          [compact]="true"
                          formControlName="border-color-{{ direction }}"
                        >
                        </ws-form-color-picker>
                      </div>
                    </div>
                  }
                }
              }
            </div>
          }
          @if (fieldExists('border-radius')) {
            <div class="mb-4">
              <div>
                <h1 class="element-settings-title">{{ 'template.element.borderRadius' | translate }}</h1>
              </div>
              <ws-form-input
                [min]="0"
                formControlName="border-radius"
                label="{{ 'template.element.radius' | translate }}"
                style="--ws-form-field-padding-top: 2em"
                suffix="px"
                type="number"
              ></ws-form-input>
            </div>
          }
          @if (atLeastOneFieldExists(['font-family', 'font-size'])) {
            <div class="mb-4" id="element-text-settings-font">
              <h1 class="element-settings-title">{{ 'template.element.font' | translate }}</h1>
              @if (fieldExists('font-family')) {
                <ws-form-select
                  [options]="fontOptions"
                  formControlName="font-family"
                  label="{{ 'template.element.fontFamily' | translate }}"
                ></ws-form-select>
              }
              @if (fieldExists('font-size')) {
                <ws-form-input
                  formControlName="font-size"
                  label="{{ 'template.element.fontSize' | translate }}"
                  style="--ws-form-field-padding-top: 2em"
                  suffix="px"
                  type="number"
                  [min]="1"
                >
                </ws-form-input>
              }
            </div>
          }
          @if (fieldExists('align') && !fieldExists('vertical-align')) {
            <div class="mb-4" id="element-settings-image-align">
              <h1 class="element-settings-title text-left">{{ 'template.element.alignment' | translate }}</h1>
              <div class="text-center">
                <span class="text-sm text-center font-bold">{{ 'template.element.horizontal' | translate }}</span>

                <div id="element-settings-image-horizontal-align">
                  <button
                    (click)="updateFormValue('align', 'left')"
                    class="ml-2"
                    mat-icon-button
                    matTooltip="{{ 'template.element.textAlignLeft' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_align_left</mat-icon>
                  </button>
                  <button
                    (click)="updateFormValue('align', 'center')"
                    class="ml-2"
                    mat-icon-button
                    matTooltip="{{ 'template.element.textAlignCenter' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_align_center</mat-icon>
                  </button>
                  <button
                    (click)="updateFormValue('align', 'right')"
                    class="ml-2"
                    mat-icon-button
                    matTooltip="{{ 'template.element.textAlignRight' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_align_right</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          }
          @if (fieldExists('align') && fieldExists('vertical-align')) {
            <div class="mb-4" id="element-settings-align">
              <h1 class="element-settings-title">{{ 'template.element.alignment' | translate }}</h1>
              <div class="grid grid-cols-2 gap-x-2 pt-2">
                <span class="text-sm text-center font-bold">{{ 'template.element.horizontal' | translate }}</span>
                <span class="text-sm text-center font-bold">{{ 'template.element.vertical' | translate }}</span>

                <div id="element-settings-horizontal-align">
                  <button
                    (click)="updateFormValue('align', 'left')"
                    class="ml-2"
                    mat-icon-button
                    matTooltip="{{ 'template.element.textAlignLeft' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_align_left</mat-icon>
                  </button>
                  <button
                    (click)="updateFormValue('align', 'center')"
                    class="ml-2"
                    mat-icon-button
                    matTooltip="{{ 'template.element.textAlignCenter' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_align_center</mat-icon>
                  </button>
                  <button
                    (click)="updateFormValue('align', 'right')"
                    class="ml-2"
                    mat-icon-button
                    matTooltip="{{ 'template.element.textAlignRight' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_align_right</mat-icon>
                  </button>
                </div>
                <div id="element-settings-vertical-align">
                  <button
                    (click)="updateFormValue('vertical-align', 'top')"
                    class="ml-2"
                    mat-icon-button
                    matTooltip="{{ 'template.element.textAlignTop' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>align_vertical_top</mat-icon>
                  </button>
                  <button
                    (click)="updateFormValue('vertical-align', 'middle')"
                    class="ml-2"
                    mat-icon-button
                    matTooltip="{{ 'template.element.textAlignCenter' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>align_vertical_center</mat-icon>
                  </button>
                  <button
                    (click)="updateFormValue('vertical-align', 'bottom')"
                    class="ml-2"
                    mat-icon-button
                    matTooltip="{{ 'template.element.textAlignBottom' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>align_vertical_bottom</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          }
          @if (paddingExists()) {
            <div class="mb-4" id="element-settings-padding">
              <h1 class="element-settings-title">{{ 'template.element.padding' | translate }}</h1>

              <div class="grid grid-cols-2 gap-x-24">
                <div class="pr-4">
                  <ws-form-input
                    [min]="0"
                    formControlName="padding-top"
                    label="{{ 'template.element.top' | translate }}"
                    style="--ws-form-field-padding-top: 2em"
                    suffix="px"
                    type="number"
                  >
                  </ws-form-input>
                </div>
                <div class="pl-4">
                  <ws-form-input
                    [min]="0"
                    formControlName="padding-bottom"
                    label="{{ 'template.element.bottom' | translate }}"
                    style="--ws-form-field-padding-top: 2em"
                    suffix="px"
                    type="number"
                  >
                  </ws-form-input>
                </div>

                <div class="pr-4">
                  <ws-form-input
                    [min]="0"
                    formControlName="padding-left"
                    label="{{ 'template.element.left' | translate }}"
                    style="--ws-form-field-padding-top: 2em"
                    suffix="px"
                    type="number"
                  >
                  </ws-form-input>
                </div>
                <div class="pl-4">
                  <ws-form-input
                    [min]="0"
                    formControlName="padding-right"
                    label="{{ 'template.element.right' | translate }}"
                    style="--ws-form-field-padding-top: 2em"
                    suffix="px"
                    type="number"
                  >
                  </ws-form-input>
                </div>
              </div>
            </div>
          }
          @if (innerPaddingExists()) {
            <div class="mb-4" id="element-settings-inner-padding">
              <h1 class="element-settings-title">{{ 'template.element.innerPadding' | translate }}</h1>

              <div class="grid grid-cols-2 gap-x-24">
                <div class="pr-4">
                  <ws-form-input
                    [min]="0"
                    formControlName="inner-padding-top"
                    label="{{ 'template.element.top' | translate }}"
                    style="--ws-form-field-padding-top: 2em"
                    suffix="px"
                    type="number"
                  >
                  </ws-form-input>
                </div>
                <div class="pl-4">
                  <ws-form-input
                    [min]="0"
                    formControlName="inner-padding-bottom"
                    label="{{ 'template.element.bottom' | translate }}"
                    style="--ws-form-field-padding-top: 2em"
                    suffix="px"
                    type="number"
                  >
                  </ws-form-input>
                </div>
                <div class="pr-4">
                  <ws-form-input
                    [min]="0"
                    formControlName="inner-padding-left"
                    label="{{ 'template.element.left' | translate }}"
                    style="--ws-form-field-padding-top: 2em"
                    suffix="px"
                    type="number"
                  >
                  </ws-form-input>
                </div>
                <div class="pl-4">
                  <ws-form-input
                    [min]="0"
                    formControlName="inner-padding-right"
                    label="{{ 'template.element.right' | translate }}"
                    style="--ws-form-field-padding-top: 2em"
                    suffix="px"
                    type="number"
                  >
                  </ws-form-input>
                </div>
              </div>
            </div>
          }
          <div class="mb-4">
            <h1 class="element-settings-title">{{ 'template.element.mobile' | translate }}</h1>
            @if (showGroupedColumns()) {
              <ws-form-toggle
                (click)="setGroupColumnsOfSection()"
                formControlName="grouped-columns"
                label="{{ 'template.element.groupedColumns' | translate }}"
              ></ws-form-toggle>
            }
            <ws-form-toggle
              (click)="hideOnMobile()"
              formControlName="hide-on-mobile"
              label="{{ 'template.element.hideOnMobile' | translate }}"
            ></ws-form-toggle>
          </div>
          <!--    <br />-->
          <!--    <br />-->
          <!--    Debug:::-->
          <!--    <br />-->
          <!--    &lt;!&ndash;    {{ selectedElement | json }}&ndash;&gt;-->
          <!--    {{ selectedElement.styles | json }}-->
          <!--    {{ selectedElement.id | json }}-->
        </form>
      }
    </ng-template>
  </div>
}
