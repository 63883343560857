@if (data.brick) {
  <mat-dialog-content>
    <div class="flex flex-col items-center">
      <span mat-dialog-title class="flex">{{ 'emailBlock.deleteEmailBlockDialog' | translate }}</span>
      {{ data.brick.name }}
      <div
        [ngStyle]="{ width: ['Image', 'Block'].includes(data.brick.type) ? '600px' : '' }"
        [innerHTML]="data.brick.getHtmlWithTestData(data.template.testData) | safeHtml"
      ></div>
    </div>
  </mat-dialog-content>
}
<div class="flex flex-wrap justify-end">
  <mat-dialog-actions>
    <div class="px-4 py-3">
      <ws-button (click)="close()" [flat]="true">
        {{ 'template.close' | translate }}
      </ws-button>

      <ws-button (click)="save()" class="bg-accent text-white" [flat]="true">
        <span class="capitalize">{{ 'delete' | translate }}</span>
      </ws-button>
    </div>
  </mat-dialog-actions>
</div>
