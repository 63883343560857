import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  input,
  model,
  OnInit,
  output,
  signal,
  ViewChild
} from '@angular/core'
import { CdkDrag, CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop'
import { AspectRatioSelectOption } from '../../../types/forms/aspect-ratio-select-option'
import { MatMenu, MatMenuTrigger } from '@angular/material/menu'
import { MatIcon } from '@angular/material/icon'
import { WsPipesModule } from '../../pipes/ws-pipes.module'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'ws-form-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatMenuTrigger, MatIcon, WsPipesModule, CdkDrag, MatMenu],
  standalone: true
})
export class ImageCropperComponent implements AfterViewInit, AfterViewChecked, OnInit {
  /**
   * The image file to be cropped.
   */
  public imageFile = input.required<File>()
  /**
   * It will resize the image to the given width. If the height is not set, the image will be resized to the given width and the aspect ratio will be maintained.
   * You should only set the width or the height, not both.
   */
  public resizeToWidth = input<number>(1024)
  /**
   * It will resize the image to the given height. If the width is not set, the image will be resized to the given height and the aspect ratio will be maintained.
   * You should only set the width or the height, not both.
   */
  public resizeToHeight = input<number>(0)
  /**
   * The minimum width of the image cropper. The image cropper will grow with the width of the container but will not be smaller than this value.
   */
  public cropperMinWidth = input<number>(300)
  /**
   * If true no fixed aspect ratio will be used. The selected image can be cropped freely moving the edges of the cropper.
   */
  public freeMode = input<boolean>(false)
  /**
   * If true a round preview will be displayed. The image will be uploaded as a square.
   * Has no effect in free mode.
   */
  public roundCropper = input<boolean>(false)
  /**
   * Webp is currently not supported by safari and therefore not supported by the image-cropper
   */
  public outputFormat = input<'jpeg' | 'png'>('jpeg')

  /**
   * The possible aspect ratios the user can select in free mode. If the user selects an aspect ratio the selection will be locked to this aspect ratio.
   */
  public aspectRatioSelectOptions = input<AspectRatioSelectOption[]>([
    { label: 'Free', value: 0, icon: 'crop_free', translations: { de: 'Frei', en: 'Free' } },
    { label: '1:1', value: 1, icon: 'crop_square' },
    { label: '16:9', value: 16 / 9, icon: 'crop_16_9' },
    { label: '9:16', value: 9 / 16, icon: 'crop_9_16' },
    { label: '4:3', value: 4 / 3, icon: 'crop_landscape' },
    { label: '3:4', value: 3 / 4, icon: 'crop_portrait' },
    { label: '3:2', value: 3 / 2, icon: 'crop_landscape' },
    { label: '2:3', value: 2 / 3, icon: 'crop_portrait' }
  ])

  /**
   * The rotation of the image in degrees.
   */
  public rotation = model<number>(0)
  /**
   * The aspect ratio of the selected aspect ratio in free mode. This will be used to keep the aspect ratio when resizing the selection.
   * You can set this value and use an empty array for the aspectRatioSelectOptions to use lock the selection to a specific aspect ratio.
   */
  public aspectRatio = model<number>(0)

  @ViewChild(HTMLCanvasElement, { static: true }) protected imageCanvas!: HTMLCanvasElement
  protected canvasId = 'image-canvas-' + Math.floor(Math.random() * 1000000)
  protected imageContext: CanvasRenderingContext2D | null = null

  /**
   * The zoom factor of the image. This will be used to zoom in and out of the image. Only used in Fixed Mode.
   */
  private zoomFactor = 1
  /**
   * The aspect ratio of the selection in free mode. This will be used to keep the aspect ratio when resizing the selection.
   */
  protected freeModeSelectionAspectRatio = 1
  /**
   * The distance the image is currently moved.
   */
  private currentDistance = 0
  /**
   * The aspect ratio of the original image. This will be used to keep the aspect ratio when resizing the image.
   */
  private originalImageAspectRatio = 1
  /**
   * The last time the image was edited. This will be used to increase the performance of the image editing.
   */
  private lastImageEditedEvent = 0
  /**
   * The last time the image cropper was resized. This will be used to increase the performance.
   */
  private lastResizedEvent = 0
  /**
   * This property is used in free mode to keep the aspect ratio of the image with the selected aspect ratio.
   */
  protected keepAspectRatio = false
  /**
   * Indicates if the user is currently moving an edge of the image cropper.
   * This is used to change the cursor when the user is moving an edge.
   */
  protected movingCorner = false

  /**
   * The position of the image on the canvas. This will be used to calculate the position of the image when moving it.
   */
  private drawPosition = { x: 0, y: 0 }
  /**
   * The offset the image was moved from the drawPosition.
   */
  private moveOffset = { x: 0, y: 0 }
  /**
   * The selected aspect ratio in free mode. This will be used to keep the aspect ratio of the image when resizing the selection.
   */
  protected selectAspectRatio: AspectRatioSelectOption = {
    label: 'Free',
    value: 0,
    icon: 'crop_free'
  }

  /**
   * The image object that will be drawn on the canvas. It will be created from the image file.
   */
  private image = new Image()
  /**
   * The resized image file that will be emitted when the image is edited or when the getResizedImage() function is called.
   */
  private exportImageFile = new File([], 'resizedImage')
  /**
   * The current width of the image cropper.
   */
  protected cropperWidth = signal<number>(0)
  /**
   * The current height of the image cropper.
   */
  protected cropperHeight = signal<number>(0)
  /**
   * The current position of the right boundary of the image cropper in free mode.
   */
  protected boundaryRight = signal<number>(0)
  /**
   * The current position of the left boundary of the image cropper in free mode.
   */
  protected boundaryLeft = signal<number>(0)
  /**
   * The current position of the top boundary of the image cropper in free mode.
   */
  protected boundaryTop = signal<number>(0)
  /**
   * The current position of the bottom boundary of the image cropper in free mode.
   */
  protected boundaryBottom = signal<number>(0)
  /**
   * The distance the right boundary is currently moved.
   */
  protected currentDragDistanceRight = signal<number>(0)
  /**
   * The distance the left boundary is currently moved.
   */
  protected currentDragDistanceLeft = signal<number>(0)
  /**
   * The distance the top boundary is currently moved.
   */
  protected currentDragDistanceTop = signal<number>(0)
  /**
   * The distance the bottom boundary is currently moved.
   */
  protected currentDragDistanceBottom = signal<number>(0)
  /**
   * Indicates if the image is currently being loaded.
   */
  protected imageLoaded = signal<boolean>(false)
  /**
   * If true the image is currently being dragged by the user.
   */
  protected dragging = signal<boolean>(false)

  /**
   * This event will be emitted when the image is edited by the user. It will contain the edited image as a file.
   */
  public imageEdited = output<File>()

  private cdRef = inject(ChangeDetectorRef)

  public ngOnInit() {
    //try to set the given aspect ratio as the selected aspect ratio
    if (this.aspectRatio() && this.freeMode() && this.aspectRatioSelectOptions().length > 0) {
      this.selectAspectRatio = this.aspectRatioSelectOptions().find(
        (option) => option.value === this.aspectRatio()
      ) ?? {
        label: 'Free',
        value: 0,
        icon: 'crop_free'
      }

      this.setFreeModeSelectionAspectRatio(this.selectAspectRatio.value)
    }

    //in fixed mode only aspect ratio 1 is currently supported
    if (!this.freeMode()) {
      this.aspectRatio.set(1)
    }
  }

  public ngAfterViewInit(): void {
    this.image.onload = () => {
      this.imageLoaded.set(true)
      this.initImageCropper()
    }

    if (this.imageFile()) {
      const reader = new FileReader()
      reader.onload = (event: any) => {
        this.image.src = event.target.result
      }
      reader.readAsDataURL(this.imageFile())
    }
  }

  public ngAfterViewChecked(): void {
    this.cdRef.detectChanges()
  }

  protected initImageCropper() {
    this.rotation.set(0)
    this.drawPosition.x = 0
    this.drawPosition.y = 0
    this.moveOffset.x = 0
    this.moveOffset.y = 0
    this.zoomFactor = 1
    this.currentDistance = 0

    // find the container of the image canvas and set the width of the cropper to the width of the container
    const imageCanvasContainer = document.getElementById('container-' + this.canvasId) as HTMLDivElement
    if (imageCanvasContainer && imageCanvasContainer.clientWidth > this.cropperMinWidth()) {
      this.cropperWidth.set(imageCanvasContainer.clientWidth)
    } else {
      this.cropperWidth.set(this.cropperMinWidth())
    }

    // set the height of the cropper depending on the aspect ratio of the image and the width of the cropper
    if (this.aspectRatio() && !this.freeMode() && !this.roundCropper()) {
      this.cropperHeight.set(this.cropperWidth() / this.aspectRatio())
    } else {
      if (!this.roundCropper()) {
        this.cropperHeight.set(this.cropperWidth() / (this.image.width / this.image.height))
      } else {
        this.cropperHeight.set(this.cropperWidth())
      }
    }

    // in free mode the aspect ratio of the image will be maintained
    if (this.freeMode()) {
      this.cropperHeight.set(Math.floor(this.cropperWidth() / (this.image.width / this.image.height)))

      if (this.aspectRatio()) {
        this.setFreeModeSelectionAspectRatio(this.aspectRatio())
      } else {
        this.resetFreeModeSelectionAspectRatio()
      }
    }

    //setTimeout is needed because without it the canvas is not yet available, and it will not draw the image
    setTimeout(() => {
      this.imageCanvas = document.getElementById(this.canvasId) as HTMLCanvasElement
      this.imageContext = this.imageCanvas.getContext('2d')

      this.initDrawImage()
      this.addEventListeners()
      this.generateFinalImage()
    }, 100)
  }

  /**
   * This function will initialize the image on the canvas. It will set some initial values and draw the image on the canvas.
   * It will also set the original image aspect ratio if it is not set yet.
   */
  private initDrawImage() {
    if (!this.imageContext) {
      return
    }

    this.imageContext.clearRect(0, 0, this.imageCanvas.width, this.imageCanvas.height)

    this.drawPosition.x = 0
    this.drawPosition.y = 0
    this.originalImageAspectRatio = this.image.width / this.image.height

    if (!this.freeMode()) {
      if (this.originalImageAspectRatio > 1) {
        this.drawPosition.x = 0
        this.drawPosition.y = 0

        this.imageContext.resetTransform()
        this.imageContext.scale(this.originalImageAspectRatio, this.originalImageAspectRatio)
        //translate is used to keep the image in the center of the canvas
        this.imageContext.translate(
          -(
            (this.imageCanvas.width * this.originalImageAspectRatio - this.imageCanvas.width) /
            this.originalImageAspectRatio /
            2
          ),
          0
        )
        this.imageContext.drawImage(
          this.image,
          this.drawPosition.x,
          this.drawPosition.y,
          this.imageCanvas.width,
          this.imageCanvas.height / this.originalImageAspectRatio
        )
      } else if (this.originalImageAspectRatio < 1) {
        this.drawPosition.x = 0
        this.drawPosition.y = 0

        this.imageContext.resetTransform()
        this.imageContext.scale(1 / this.originalImageAspectRatio, 1 / this.originalImageAspectRatio)
        //translate is used to keep the image in the center of the canvas
        this.imageContext.translate(
          0,
          -(
            ((this.imageCanvas.height / this.originalImageAspectRatio - this.imageCanvas.height) *
              this.originalImageAspectRatio) /
            2
          )
        )
        this.imageContext.drawImage(
          this.image,
          this.drawPosition.x,
          this.drawPosition.y,
          this.imageCanvas.width * this.originalImageAspectRatio,
          this.imageCanvas.height
        )
      } else {
        this.drawPosition.x = 0
        this.drawPosition.y = 0

        this.imageContext.drawImage(
          this.image,
          this.drawPosition.x,
          this.drawPosition.y,
          this.imageCanvas.width,
          this.imageCanvas.height
        )
      }
    }

    this.drawImage()
  }

  /**
   * This function is used when the image preview changes and have to be drawn again.
   */
  private drawImage() {
    if (!this.imageContext) {
      return
    }

    this.imageContext.clearRect(0, 0, this.imageCanvas.width, this.imageCanvas.height)
    this.imageContext.save()

    if (!this.freeMode()) {
      if (this.originalImageAspectRatio > 1) {
        //here we draw a landscape image into the square canvas
        this.imageContext.clearRect(0, 0, this.imageCanvas.width, this.imageCanvas.height)
        this.imageContext.save()

        // translate -> rotate -> scale -> translate is used to keep the image in the center of the canvas when rotating or zooming
        this.imageContext.translate(
          this.imageCanvas.width / 2,
          this.imageCanvas.height / this.originalImageAspectRatio / 2
        )
        if (this.rotation() !== 0) {
          this.imageContext.rotate((this.rotation() * Math.PI) / 180)
        }
        this.imageContext.scale(this.zoomFactor, this.zoomFactor)
        this.imageContext.translate(
          -this.imageCanvas.width / 2,
          -this.imageCanvas.height / this.originalImageAspectRatio / 2
        )

        this.imageContext.drawImage(
          this.image,
          this.drawPosition.x,
          this.drawPosition.y,
          this.imageCanvas.width,
          this.imageCanvas.height / this.originalImageAspectRatio
        )

        this.imageContext.restore()
      } else if (this.originalImageAspectRatio < 1) {
        //here we draw a portrait image into the square canvas
        this.imageContext.clearRect(0, 0, this.imageCanvas.width, this.imageCanvas.height)
        this.imageContext.save()

        // translate -> rotate -> scale -> translate is used to keep the image in the center of the canvas when rotating or zooming
        this.imageContext.translate(
          (this.imageCanvas.width * this.originalImageAspectRatio) / 2,
          this.imageCanvas.height / 2
        )
        if (this.rotation() !== 0) {
          this.imageContext.rotate((this.rotation() * Math.PI) / 180)
        }
        this.imageContext.scale(this.zoomFactor, this.zoomFactor)
        this.imageContext.translate(
          (-this.imageCanvas.width * this.originalImageAspectRatio) / 2,
          -this.imageCanvas.height / 2
        )

        this.imageContext.drawImage(
          this.image,
          this.drawPosition.x,
          this.drawPosition.y,
          this.imageCanvas.width * this.originalImageAspectRatio,
          this.imageCanvas.height
        )

        this.imageContext.restore()
      } else {
        //here we draw a square image into the square canvas
        this.imageContext.clearRect(0, 0, this.imageCanvas.width, this.imageCanvas.height)
        this.imageContext.save()

        // translate -> rotate -> scale -> translate is used to keep the image in the center of the canvas when rotating or zooming
        this.imageContext.translate(this.imageCanvas.width / 2, this.imageCanvas.height / 2)
        if (this.rotation() !== 0) {
          this.imageContext.rotate((this.rotation() * Math.PI) / 180)
        }
        this.imageContext.scale(this.zoomFactor, this.zoomFactor)
        this.imageContext.translate(-this.imageCanvas.width / 2, -this.imageCanvas.height / 2)

        this.imageContext.drawImage(
          this.image,
          this.drawPosition.x,
          this.drawPosition.y,
          this.imageCanvas.width,
          this.imageCanvas.height
        )

        this.imageContext.restore()
      }
    } else {
      //in Free Mode the canvas aspect ratio is fitted to the image aspect ratio, so no need to adjust the image
      // translate -> rotate -> scale -> translate is used to keep the image in the center of the canvas when rotating or zooming
      this.imageContext.translate(this.imageCanvas.width / 2, this.imageCanvas.height / 2)
      if (this.rotation() !== 0) {
        this.imageContext.rotate((this.rotation() * Math.PI) / 180)
      }
      this.imageContext.translate(-this.imageCanvas.width / 2, -this.imageCanvas.height / 2)

      if (this.rotation() === 90 || this.rotation() === -90 || this.rotation() === 270 || this.rotation() === -270) {
        this.imageContext.drawImage(
          this.image,
          this.drawPosition.x - (this.imageCanvas.height - this.imageCanvas.width) / 2,
          this.drawPosition.y - (this.imageCanvas.width - this.imageCanvas.height) / 2,
          this.imageCanvas.height,
          this.imageCanvas.width
        )
      } else {
        this.imageContext.drawImage(
          this.image,
          this.drawPosition.x,
          this.drawPosition.y,
          this.imageCanvas.width,
          this.imageCanvas.height
        )
      }

      this.imageContext.restore()
    }
  }

  private addEventListeners() {
    window.addEventListener('resize', () => {
      this.lastResizedEvent = Date.now()

      setTimeout(() => {
        if (Date.now() - this.lastResizedEvent >= 250) {
          this.lastResizedEvent = Date.now()
          this.initImageCropper()
        }
      }, 250)
    })

    if (this.imageCanvas && !this.freeMode()) {
      this.imageCanvas.addEventListener(
        'wheel',
        (event: WheelEvent) => {
          this.handleScroll(event)
        },
        { passive: false }
      )
      this.imageCanvas.addEventListener('mousemove', (e: MouseEvent) => {
        this.handleMove(e)
      })
      this.imageCanvas.addEventListener('mousedown', (e: MouseEvent) => {
        this.handleMouseDown(e)
      })
      this.imageCanvas.addEventListener('mouseup', (e: MouseEvent) => {
        this.handleMouseUp(e)
      })
      this.imageCanvas.addEventListener('mouseout', (e: MouseEvent) => {
        this.handleMouseOut(e)
      })
      this.imageCanvas.addEventListener('mouseenter', (e: MouseEvent) => {
        this.handleMouseEnter(e)
      })
      this.imageCanvas.addEventListener(
        'touchmove',
        (e: TouchEvent) => {
          this.handleMove(e)
        },
        { passive: false }
      )
      this.imageCanvas.addEventListener(
        'touchstart',
        (e: TouchEvent) => {
          this.handleTouchStart(e)
        },
        { passive: false }
      )
      this.imageCanvas.addEventListener('touchend', (e: TouchEvent) => {
        this.handleTouchEnd(e)
      })
    }
  }

  private handleScroll(event: WheelEvent) {
    const delta = event.deltaY ? -event.deltaY / 2400 : 0

    if (delta) {
      this.zoom(delta)
      return event.preventDefault()
    }
  }

  private handleTouchStart(event: TouchEvent) {
    this.moveOffset.x = event.touches[0].clientX
    this.moveOffset.y = event.touches[0].clientY
    this.dragging.set(true)
  }

  private handleTouchEnd(event: TouchEvent) {
    this.dragging.set(false)
    this.currentDistance = 0
    this.generateFinalImage()
  }

  private handleMouseDown(event: MouseEvent) {
    event.preventDefault()
    this.moveOffset.x = event.offsetX
    this.moveOffset.y = event.offsetY
    this.dragging.set(true)
  }

  private handleMouseUp(event: MouseEvent) {
    this.dragging.set(false)
    this.currentDistance = 0
    this.generateFinalImage()
  }

  /**
   * This function will move the image on the canvas. It will calculate the new position of the image and draw it on the canvas.
   */
  private handleMove(event: any) {
    event.preventDefault()

    if (this.imageContext && this.dragging()) {
      const pointerPosition = { x: 0, y: 0 }

      if (event.offsetX) {
        pointerPosition.x = event.offsetX
        pointerPosition.y = event.offsetY
      } else if (event.touches && event.touches.length === 1) {
        pointerPosition.x = event.touches[0].clientX
        pointerPosition.y = event.touches[0].clientY
      } else if (event.type == 'touchmove' && event.touches.length === 2) {
        const touch1 = { x: event.touches[0].clientX, y: event.touches[0].clientY }
        const touch2 = { x: event.touches[1].clientX, y: event.touches[1].clientY }
        pointerPosition.x = 0
        pointerPosition.y = 0
        this.moveOffset.x = 0
        this.moveOffset.y = 0
        const currentDistance = (touch1.x - touch2.x) ** 2 + (touch1.y - touch2.y) ** 2

        if (this.currentDistance === 0) {
          this.currentDistance = currentDistance
        }

        const distance = currentDistance - this.currentDistance

        if (distance > 0) {
          this.zoom(distance / 100000)
        } else if (distance < 0) {
          this.zoom(distance / 100000)
        }

        this.currentDistance = currentDistance
      } else {
        return
      }

      if (this.rotation()) {
        switch (this.rotation()) {
          case 90:
            this.drawPosition.x -=
              (this.moveOffset.y - pointerPosition.y) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            this.drawPosition.y +=
              (this.moveOffset.x - pointerPosition.x) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            break
          case 180:
            this.drawPosition.x +=
              (this.moveOffset.x - pointerPosition.x) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            this.drawPosition.y +=
              (this.moveOffset.y - pointerPosition.y) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            break
          case 270:
            this.drawPosition.x +=
              (this.moveOffset.y - pointerPosition.y) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            this.drawPosition.y -=
              (this.moveOffset.x - pointerPosition.x) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            break
          case -90:
            this.drawPosition.x +=
              (this.moveOffset.y - pointerPosition.y) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            this.drawPosition.y -=
              (this.moveOffset.x - pointerPosition.x) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            break
          case -180:
            this.drawPosition.x +=
              (this.moveOffset.x - pointerPosition.x) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            this.drawPosition.y +=
              (this.moveOffset.y - pointerPosition.y) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            break
          case -270:
            this.drawPosition.x -=
              (this.moveOffset.y - pointerPosition.y) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            this.drawPosition.y +=
              (this.moveOffset.x - pointerPosition.x) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            break
          default:
            this.drawPosition.x -=
              (this.moveOffset.x - pointerPosition.x) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
            this.drawPosition.y -=
              (this.moveOffset.y - pointerPosition.y) /
              this.zoomFactor /
              (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
        }
      } else {
        this.drawPosition.x -=
          (this.moveOffset.x - pointerPosition.x) /
          this.zoomFactor /
          (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
        this.drawPosition.y -=
          (this.moveOffset.y - pointerPosition.y) /
          this.zoomFactor /
          (this.originalImageAspectRatio > 1 ? this.originalImageAspectRatio : 1 / this.originalImageAspectRatio)
      }

      this.keepInBounds()

      this.moveOffset.x = pointerPosition.x
      this.moveOffset.y = pointerPosition.y

      this.drawImage()
    }
  }

  private handleMouseOut(event: MouseEvent) {
    if (this.dragging()) {
      this.dragging.set(false)
      this.currentDistance = 0
      this.generateFinalImage()
    }
  }

  private handleMouseEnter(event: MouseEvent) {
    //TODO: implement
    // console.log('ENTER', e)
    // this.offsetX = e.offsetX
    // this.offsetY = e.offsetY
    // this.dragging = true
  }

  /**
   * This function will reset the image cropper to its initial state.
   */
  // protected reset() {
  //   this.rotation.set(0)
  //   this.drawPosition.x = 0
  //   this.drawPosition.y = 0
  //   this.moveOffset.x = 0
  //   this.moveOffset.y = 0
  //   this.zoomFactor = 1
  //   this.currentDistance = 0
  //
  //   if (this.freeMode()) {
  //     //in Free Mode the canvas aspect ratio is fitted to the image aspect ratio, so we may need to adjust the canvas size
  //     this.cropperHeight.set(Math.floor(this.cropperWidth() / (this.image.width / this.image.height)))
  //
  //     if (this.aspectRatio()) {
  //       this.setFreeModeSelectionAspectRatio(this.aspectRatio())
  //     } else {
  //       this.resetFreeModeSelectionAspectRatio()
  //     }
  //   }
  //
  //   //setTimeout is needed because without it the canvas is not yet available, and it will not draw the image
  //   setTimeout(() => {
  //     this.initDrawImage()
  //     this.generateFinalImage()
  //   }, 100)
  // }

  /**
   * This function will rotate the image by the given degrees.
   */
  protected rotate(rotationToAdd: number) {
    if (!this.imageContext) {
      return
    }

    if (this.freeMode()) {
      this.originalImageAspectRatio = this.cropperWidth() / this.cropperHeight()

      this.cropperHeight.set(this.cropperWidth() * this.originalImageAspectRatio)

      this.boundaryLeft.set(0)
      this.boundaryRight.set(this.cropperWidth())
      this.boundaryTop.set(0)
      this.boundaryBottom.set(this.cropperHeight())

      if (this.keepAspectRatio) {
        this.setFreeModeSelectionAspectRatio(this.selectAspectRatio.value)
      } else {
        this.freeModeSelectionAspectRatio = this.cropperWidth() / this.cropperHeight()
      }

      this.rotation.update((rotation) => (rotation + rotationToAdd) % 360)
    } else {
      this.rotation.update((rotation) => (rotation + rotationToAdd) % 360)
    }

    //setTimeout is needed because without it the canvas is not yet available, and it will not draw the image
    setTimeout(() => {
      this.drawImage()
      this.generateFinalImage()
    }, 200)
  }

  /**
   * This function will zoom the image in or out by the given delta.
   */
  protected zoom(delta: number) {
    if (!this.imageContext) {
      return
    }

    if (this.zoomFactor + this.zoomFactor * delta < 1) {
      if (this.zoomFactor !== 1) {
        this.zoomFactor = 1
      } else {
        return
      }
    } else {
      this.zoomFactor += this.zoomFactor * delta
    }

    if (delta < 0) {
      this.keepInBounds()
    }
    this.drawImage()
    this.generateFinalImage()
  }

  /**
   * This function will keep the image in the bounds of the canvas by keeping the drawPosition inside. It will be used when the image is moved or zoomed in Fixed Mode.
   */
  private keepInBounds() {
    let maxMoveX = 0
    let maxMoveY = 0

    //first we will calculate how far the image is allowed to move in x and y direction, depending on the zoom factor and the aspect ratio of the image
    if (this.originalImageAspectRatio > 1) {
      maxMoveX =
        (this.imageCanvas.width * this.zoomFactor * this.originalImageAspectRatio - this.imageCanvas.width) /
        this.originalImageAspectRatio /
        2 /
        this.zoomFactor

      maxMoveY =
        (this.imageCanvas.height * this.zoomFactor - this.imageCanvas.height) /
        this.originalImageAspectRatio /
        2 /
        this.zoomFactor
    } else if (this.originalImageAspectRatio < 1) {
      maxMoveX =
        ((this.imageCanvas.width * this.zoomFactor - this.imageCanvas.width) * this.originalImageAspectRatio) /
        2 /
        this.zoomFactor

      maxMoveY =
        (((this.imageCanvas.height * this.zoomFactor) / this.originalImageAspectRatio - this.imageCanvas.height) *
          this.originalImageAspectRatio) /
        2 /
        this.zoomFactor
    } else {
      maxMoveX = (this.imageCanvas.width * this.zoomFactor - this.imageCanvas.width) / 2 / this.zoomFactor
      maxMoveY = (this.imageCanvas.height * this.zoomFactor - this.imageCanvas.height) / 2 / this.zoomFactor
    }

    //then we will check if the image is inside the x direction bounds and if not we will set the drawPosition to the maximum allowed move
    if (this.drawPosition.x > maxMoveX) {
      this.drawPosition.x = maxMoveX
    } else if (this.drawPosition.x < -maxMoveX) {
      this.drawPosition.x = -maxMoveX
    }

    //same for y direction
    if (this.drawPosition.y > maxMoveY) {
      this.drawPosition.y = maxMoveY
    } else if (this.drawPosition.y < -maxMoveY) {
      this.drawPosition.y = -maxMoveY
    }
  }

  /**
   * This function will generate the final image that will be emitted when the image is edited.
   * It has a timeout and debounce of 500ms and to increase the performance of the image editing.
   */
  protected generateFinalImage() {
    if (!this.imageContext) {
      return
    }

    this.lastImageEditedEvent = Date.now()

    setTimeout(() => {
      if (Date.now() - this.lastImageEditedEvent >= 500) {
        this.lastImageEditedEvent = Date.now()
        this.drawResizedImage()
      }
    }, 500)
  }

  /**
   * This function will draw the resized image on a canvas and create a file from it. The file will be emitted as an event.
   * The image is drawn by using the original image and the current position, zoom factor, and rotation of the image.
   */
  private drawResizedImage() {
    const exportCanvas = document.createElement('canvas')
    const exportContext = exportCanvas.getContext('2d')
    if (exportContext) {
      //set the final width and height of the image
      const finalWidth: number = this.getFinalWidth()
      const finalHeight: number = this.getFinalHeight()
      exportCanvas.width = finalWidth
      exportCanvas.height = finalHeight

      if (this.freeMode()) {
        //translate -> rotate -> scale -> translate is used to keep the image in the center of the canvas when rotating or zooming
        if (this.rotation() !== 0) {
          exportContext.translate(exportCanvas.width / 2, exportCanvas.height / 2)
          exportContext.rotate((this.rotation() * Math.PI) / 180)
          exportContext.translate(-exportCanvas.width / 2, -exportCanvas.height / 2)
        }

        //when rotated we have to use the boundaries because the original image is not rotated, only the canvas
        if (this.rotation() === 90 || this.rotation() === -270) {
          exportContext.save()
          exportContext.drawImage(
            this.image,
            this.boundaryTop() * (this.image.width / this.imageCanvas.height),
            this.image.height - this.boundaryRight() * (this.image.height / this.imageCanvas.width),
            (this.boundaryBottom() - this.boundaryTop()) * (this.image.width / this.imageCanvas.height),
            (this.boundaryRight() - this.boundaryLeft()) * (this.image.height / this.imageCanvas.width),
            -((exportCanvas.height - exportCanvas.width) / 2),
            (exportCanvas.height - exportCanvas.width) / 2,
            finalHeight,
            finalWidth
          )
          exportContext.restore()
        } else if (this.rotation() === -90 || this.rotation() === 270) {
          exportContext.save()
          exportContext.drawImage(
            this.image,
            this.image.width - this.boundaryBottom() * (this.image.width / this.imageCanvas.height),
            this.boundaryLeft() * (this.image.height / this.imageCanvas.width),
            (this.boundaryBottom() - this.boundaryTop()) * (this.image.width / this.imageCanvas.height),
            (this.boundaryRight() - this.boundaryLeft()) * (this.image.height / this.imageCanvas.width),
            -((exportCanvas.height - exportCanvas.width) / 2),
            (exportCanvas.height - exportCanvas.width) / 2,
            finalHeight,
            finalWidth
          )
          exportContext.restore()
        } else {
          exportContext.drawImage(
            this.image,
            this.boundaryLeft() * (this.image.width / this.imageCanvas.width),
            this.boundaryTop() * (this.image.height / this.imageCanvas.height),
            (this.boundaryRight() - this.boundaryLeft()) * (this.image.width / this.imageCanvas.width),
            (this.boundaryBottom() - this.boundaryTop()) * (this.image.height / this.imageCanvas.height),
            0,
            0,
            finalWidth,
            finalHeight
          )
        }
      } else {
        const minScaleFactor = Math.min(this.image.width / finalWidth, this.image.height / finalHeight)
        const scaleFactor = {
          x: this.image.width / finalWidth,
          y: this.image.height / finalHeight
        }

        exportContext.clearRect(0, 0, finalWidth, finalHeight)

        //translate -> rotate -> scale -> translate is used to keep the image in the center of the canvas when rotating or zooming
        if (this.rotation() !== 0) {
          exportContext.translate(finalWidth / 2, finalHeight / 2)
          exportContext.rotate((this.rotation() * Math.PI) / 180)
          exportContext.translate(-finalWidth / 2, -finalHeight / 2)
        }

        //the zeroPointOriginalImage is the middle point of the original image
        const zeroPointOriginalImage = {
          x: this.image.width / 2 - (finalWidth * (minScaleFactor / this.zoomFactor)) / 2,
          y: this.image.height / 2 - (finalHeight * (minScaleFactor / this.zoomFactor)) / 2
        }

        if (this.originalImageAspectRatio > 1) {
          //the drawPointOriginalImage is the point where the image should be drawn on the original image, it's the position on the x- and y-axis with the zeroPointOriginalImage as 0
          const drawPointOriginalImage = {
            x: this.drawPosition.x * (this.image.width / this.cropperWidth()),
            y: this.drawPosition.y * ((this.image.height / this.cropperHeight()) * (scaleFactor.x / scaleFactor.y))
          }

          exportContext.drawImage(
            this.image,
            zeroPointOriginalImage.x - drawPointOriginalImage.x,
            zeroPointOriginalImage.y - drawPointOriginalImage.y,
            (finalWidth * minScaleFactor) / this.zoomFactor,
            (finalHeight * minScaleFactor) / this.zoomFactor,
            0,
            0,
            finalWidth,
            finalHeight
          )

          exportContext.restore()
        } else if (this.originalImageAspectRatio < 1) {
          //the drawPointOriginalImage is the point where the image should be drawn on the original image, it's the position on the x- and y-axis with the zeroPointOriginalImage as 0
          const drawPointOriginalImage = {
            x: this.drawPosition.x * ((this.image.width / this.cropperWidth()) * (scaleFactor.y / scaleFactor.x)),
            y: this.drawPosition.y * (this.image.height / this.cropperHeight())
          }

          exportContext.drawImage(
            this.image,
            zeroPointOriginalImage.x - drawPointOriginalImage.x,
            zeroPointOriginalImage.y - drawPointOriginalImage.y,
            (finalWidth * scaleFactor.x) / this.zoomFactor,
            (finalHeight * scaleFactor.x) / this.zoomFactor,
            0,
            0,
            finalWidth,
            finalHeight
          )

          exportContext.restore()
        } else {
          exportContext.drawImage(
            this.image,
            zeroPointOriginalImage.x - this.drawPosition.x * (this.image.width / this.cropperWidth()),
            zeroPointOriginalImage.y - this.drawPosition.y * (this.image.height / this.cropperHeight()),
            (finalWidth * minScaleFactor) / this.zoomFactor,
            (finalHeight * minScaleFactor) / this.zoomFactor,
            0,
            0,
            finalWidth,
            finalHeight
          )

          exportContext.restore()
        }
      }

      //toDataURL is used despite toBlob has better performance because toBlob is async and sometimes the result is too late
      const exportDataURL = exportCanvas.toDataURL('image/' + this.outputFormat(), 1.0)
      this.exportImageFile = this.dataURLtoImageFile(exportDataURL, this.imageFile.name)

      this.imageEdited.emit(this.exportImageFile)
    }
  }

  /**
   * This function will calculate the final width of the image that will be exported.
   */
  private getFinalWidth(): number {
    if (this.resizeToWidth()) {
      //we check if the width is smaller than the original image consider the zoom factor to avoid upscaling
      if (this.resizeToWidth() <= this.image.width / this.zoomFactor) {
        return this.resizeToWidth()
      } else {
        //if the width is bigger than the original image we return the original image width because it's the maximum we can use
        return this.image.width / this.zoomFactor
      }
    }

    if (!this.freeMode()) {
      //if we are in Fixed Mode and no resizeToWidth is set we check if there is a resizeToHeight which we use to calculate the width
      if (this.resizeToHeight()) {
        return this.getFinalHeight() * this.aspectRatio()
      }

      //if there is also no resizeToHeight set, we calculate the width by using the original image width, the zoom factor and the aspect ratio
      if (this.aspectRatio() > 1) {
        return (this.image.height / this.zoomFactor) * this.aspectRatio()
      } else {
        return this.image.width / this.zoomFactor
      }
    } else {
      //if we are in Free Mode and no resizeToWidth is set we check if there is a resizeToHeight which we use to calculate the width using the selected aspect ratio
      if (this.resizeToHeight()) {
        return this.getFinalHeight() * this.freeModeSelectionAspectRatio
      }
      //calculate the width by using the current boundaries in proportion to the original image
      return (this.boundaryRight() - this.boundaryLeft()) * (this.image.width / this.imageCanvas.width)
    }
  }

  private getFinalHeight(): number {
    if (this.resizeToHeight() && !this.resizeToWidth()) {
      //we check if the height is smaller than the original image consider the zoom factor to avoid upscaling
      if (this.resizeToHeight() <= this.image.height / this.zoomFactor) {
        return this.resizeToHeight()
      } else {
        //if the height is bigger than the original image we return the original image height because it's the maximum we can use
        return this.image.height / this.zoomFactor
      }
    }

    if (!this.freeMode()) {
      //if we are in Fixed Mode and no resizeToHeight is set we check if there is a resizeToWidth which we use to calculate the height
      if (this.resizeToWidth()) {
        return this.getFinalWidth() / this.aspectRatio()
      }

      //if there is also no resizeToWidth set, we calculate the height by using the original image height, the zoom factor and the aspect ratio
      if (this.aspectRatio() > 1) {
        return this.image.height / this.zoomFactor
      } else {
        return this.image.width / this.zoomFactor / this.aspectRatio()
      }
    } else {
      //if we are in Free Mode and no resizeToHeight is set we check if there is a resizeToWidth which we use to calculate the height using the selected aspect ratio
      if (this.resizeToWidth()) {
        return this.getFinalWidth() / this.freeModeSelectionAspectRatio
      }

      //calculate the height by using the current boundaries in proportion to the original image
      return (this.boundaryBottom() - this.boundaryTop()) * (this.image.height / this.imageCanvas.height)
    }
  }

  /**
   * This function will convert a dataURL string to a file object.
   */
  private dataURLtoImageFile(dataurl: string, filename: string): File {
    const arr = dataurl.split(',')
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, {
      type: 'image/' + this.outputFormat(),
      lastModified: Date.now()
    })
  }

  /**
   * This function will set the cursor depending on the edge the user is currently moving.
   */
  protected edgeElementMoveStarted(corner: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left') {
    this.movingCorner = true

    switch (corner) {
      case 'top-left':
        document.body.style.cursor = 'nwse-resize'
        break
      case 'top-right':
        document.body.style.cursor = 'nesw-resize'
        break
      case 'bottom-right':
        document.body.style.cursor = 'nwse-resize'
        break
      case 'bottom-left':
        document.body.style.cursor = 'nesw-resize'
        break
    }
  }

  /**
   * This function is called when the user moves the edges of the image cropper in Free Mode. It will check if the edge is still inside the boundaries and update the boundaries if necessary.
   */
  protected edgeElementMoved(
    corner: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left' | 'top' | 'right' | 'bottom' | 'left',
    cdkDragMove: CdkDragMove
  ) {
    if (this.keepAspectRatio && corner !== 'right' && corner !== 'left' && corner !== 'top' && corner !== 'bottom') {
      //if the aspect ratio is fixed, we only use the x distance to keep the aspect ratio
      this.moveAndKeepSelectionAspectRatio(corner, cdkDragMove.distance.x)
    } else {
      switch (corner) {
        case 'top-right':
        case 'bottom-right':
        case 'right':
          if (
            this.isInBoundary(
              cdkDragMove.pointerPosition.x,
              cdkDragMove.source.element.nativeElement.parentElement,
              'left'
            )
          ) {
            this.currentDragDistanceRight.set(cdkDragMove.distance.x)
          }
          break
        case 'top-left':
        case 'bottom-left':
        case 'left':
          if (
            this.isInBoundary(
              cdkDragMove.pointerPosition.x,
              cdkDragMove.source.element.nativeElement.parentElement,
              'right'
            )
          ) {
            this.currentDragDistanceLeft.set(cdkDragMove.distance.x)
          }
          break
      }

      switch (corner) {
        case 'top-right':
        case 'top-left':
        case 'top':
          if (
            this.isInBoundary(
              cdkDragMove.pointerPosition.y,
              cdkDragMove.source.element.nativeElement.parentElement,
              'bottom'
            )
          ) {
            this.currentDragDistanceTop.set(cdkDragMove.distance.y)
          }
          break
        case 'bottom-right':
        case 'bottom-left':
        case 'bottom':
          if (
            this.isInBoundary(
              cdkDragMove.pointerPosition.y,
              cdkDragMove.source.element.nativeElement.parentElement,
              'top'
            )
          ) {
            this.currentDragDistanceBottom.set(cdkDragMove.distance.y)
          }
          break
      }
    }
  }

  /**
   * This function is used when a fixed aspect ratio is selected. It will ensure that the aspect ratio is kept when the user moves the edges of the image cropper in Free Mode.
   */
  private moveAndKeepSelectionAspectRatio(
    edge: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left',
    distance: number
  ) {
    switch (edge) {
      case 'top-left':
        if (
          this.boundaryLeft() + distance > 0 &&
          this.boundaryTop() + distance / this.freeModeSelectionAspectRatio > 0 &&
          this.boundaryLeft() + distance < this.imageCanvas.width &&
          this.boundaryTop() + distance / this.freeModeSelectionAspectRatio < this.imageCanvas.height &&
          this.boundaryLeft() + distance < this.boundaryRight() - 56
        ) {
          this.currentDragDistanceLeft.set(distance)
          this.currentDragDistanceTop.set(distance / this.aspectRatio())
        } else {
        }
        break
      case 'top-right':
        if (
          this.boundaryRight() + distance > 0 &&
          this.boundaryTop() - distance / this.freeModeSelectionAspectRatio > 0 &&
          this.boundaryRight() + distance < this.imageCanvas.width &&
          this.boundaryTop() - distance / this.freeModeSelectionAspectRatio < this.imageCanvas.height &&
          this.boundaryRight() + distance > this.boundaryLeft() + 56
        ) {
          this.currentDragDistanceRight.set(distance)
          this.currentDragDistanceTop.set(-distance / this.aspectRatio())
        }

        break
      case 'bottom-right':
        if (
          this.boundaryRight() + distance > 0 &&
          this.boundaryBottom() + distance / this.freeModeSelectionAspectRatio > 0 &&
          this.boundaryRight() + distance < this.imageCanvas.width &&
          this.boundaryBottom() + distance / this.freeModeSelectionAspectRatio < this.imageCanvas.height &&
          this.boundaryRight() + distance > this.boundaryLeft() + 56
        ) {
          this.currentDragDistanceRight.set(distance)
          this.currentDragDistanceBottom.set(distance / this.aspectRatio())
        }
        break
      case 'bottom-left':
        if (
          this.boundaryLeft() + distance > 0 &&
          this.boundaryBottom() - distance / this.freeModeSelectionAspectRatio > 0 &&
          this.boundaryLeft() + distance < this.imageCanvas.width &&
          this.boundaryBottom() - distance / this.freeModeSelectionAspectRatio < this.imageCanvas.height &&
          this.boundaryLeft() + distance < this.boundaryRight() - 56
        ) {
          this.currentDragDistanceLeft.set(distance)
          this.currentDragDistanceBottom.set(-distance / this.aspectRatio())
        }
        break
    }
  }

  /**
   * This function is called when the user stops moving the edges of the image cropper in Free Mode. It will update the boundaries of the image cropper (and therefore the final image to export) and reset the drag distances.
   */
  protected edgeElementMoveEnded() {
    this.movingCorner = false
    document.body.style.removeProperty('cursor')

    if (this.currentDragDistanceLeft() !== 0) {
      this.boundaryLeft.update((distance): number => {
        if (distance + this.currentDragDistanceLeft() < 0) {
          return 0
        } else if (distance + this.currentDragDistanceLeft() > this.imageCanvas.width) {
          return this.imageCanvas.width
        } else {
          return distance + this.currentDragDistanceLeft()
        }
      })
    }

    if (this.currentDragDistanceTop() !== 0) {
      this.boundaryTop.update((distance): number => {
        if (distance + this.currentDragDistanceTop() < 0) {
          return 0
        } else if (distance + this.currentDragDistanceTop() > this.imageCanvas.height) {
          return this.imageCanvas.height
        } else {
          return distance + this.currentDragDistanceTop()
        }
      })
    }

    if (this.currentDragDistanceRight() !== 0) {
      this.boundaryRight.update((distance): number => {
        if (distance + this.currentDragDistanceRight() < 0) {
          return 0
        } else if (distance + this.currentDragDistanceRight() > this.imageCanvas.width) {
          return this.imageCanvas.width
        } else {
          return distance + this.currentDragDistanceRight()
        }
      })
    }

    if (this.currentDragDistanceBottom() !== 0) {
      this.boundaryBottom.update((distance): number => {
        if (distance + this.currentDragDistanceBottom() < 0) {
          return 0
        } else if (distance + this.currentDragDistanceBottom() > this.imageCanvas.height) {
          return this.imageCanvas.height
        } else {
          return distance + this.currentDragDistanceBottom()
        }
      })
    }

    this.resetAfterDragEndedInFreeMode()
  }

  /**
   * This function calculates if the edge is still inside the boundaries when the user moves the edge in Free Mode.
   */
  protected isInBoundary(
    pointerPosition: number,
    parent: HTMLElement | null,
    boundary: 'top' | 'bottom' | 'right' | 'left'
  ) {
    switch (boundary) {
      case 'left':
        return pointerPosition > (parent?.getBoundingClientRect().left ?? 0) + this.boundaryLeft() + 50
      case 'right':
        return pointerPosition < (parent?.getBoundingClientRect().left ?? 0) + this.boundaryRight() - 50
      case 'top':
        return pointerPosition > (parent?.getBoundingClientRect().top ?? 0) + this.boundaryTop() + 50
      case 'bottom':
        return pointerPosition < (parent?.getBoundingClientRect().top ?? 0) + this.boundaryBottom() - 50
    }
  }

  /**
   * This function is called when the user moves the preview image in Free Mode. It will not check if the image is still inside the boundaries, because it's already ensured by the cdkDragBoundary attribute.
   * The preview image is the current cutout of the image, it is not the image which will be exported. The final image will be updated when the user stops moving the image.
   */
  protected previewDragMoved(cdkDragMove: CdkDragMove) {
    this.currentDragDistanceTop.set(cdkDragMove.distance.y)
    this.currentDragDistanceRight.set(cdkDragMove.distance.x)
    this.currentDragDistanceBottom.set(cdkDragMove.distance.y)
    this.currentDragDistanceLeft.set(cdkDragMove.distance.x)
  }

  /**
   * This function is called when the user stops moving the preview image in Free Mode. It will update the boundaries of the image cropper (and therefore the final image to export) and reset the drag distances.
   */
  protected previewDragEnded(cdkDragEnd: CdkDragEnd) {
    const currentWidth = this.boundaryRight() - this.boundaryLeft()
    const currentHeight = this.boundaryBottom() - this.boundaryTop()

    if (cdkDragEnd.distance.x > 0) {
      this.boundaryRight.update((distance) => {
        if (distance + cdkDragEnd.distance.x < this.imageCanvas.width) {
          return distance + cdkDragEnd.distance.x
        } else {
          return this.imageCanvas.width
        }
      })

      this.boundaryLeft.set(this.boundaryRight() - currentWidth)
    } else {
      this.boundaryLeft.update((distance) => {
        if (distance + cdkDragEnd.distance.x > 0) {
          return distance + cdkDragEnd.distance.x
        } else {
          return 0
        }
      })

      this.boundaryRight.set(this.boundaryLeft() + currentWidth)
    }

    if (cdkDragEnd.distance.y > 0) {
      this.boundaryBottom.update((distance) => {
        if (distance + cdkDragEnd.distance.y < this.imageCanvas.height) {
          return distance + cdkDragEnd.distance.y
        } else {
          return this.imageCanvas.height
        }
      })

      this.boundaryTop.set(this.boundaryBottom() - currentHeight)
    } else {
      this.boundaryTop.update((distance) => {
        if (distance + cdkDragEnd.distance.y > 0) {
          return distance + cdkDragEnd.distance.y
        } else {
          return 0
        }
      })

      this.boundaryBottom.set(this.boundaryTop() + currentHeight)
    }

    this.resetAfterDragEndedInFreeMode()
  }

  /**
   * This function will reset the freeModeSelectionAspectRatio and the drag distances when the user stops moving the preview image in Free Mode.
   * It will also call the function to generate the final image to export.
   */
  private resetAfterDragEndedInFreeMode() {
    if (!this.keepAspectRatio) {
      this.freeModeSelectionAspectRatio =
        (this.boundaryRight() - this.boundaryLeft()) / (this.boundaryBottom() - this.boundaryTop())
    }

    this.currentDragDistanceRight.set(0)
    this.currentDragDistanceLeft.set(0)
    this.currentDragDistanceTop.set(0)
    this.currentDragDistanceBottom.set(0)
    this.generateFinalImage()
  }

  /**
   * This function is called when the user selects a new aspect ratio in Free Mode. It will update the boundaries of the image cropper (and therefore the final image to export) and reset the drag distances.
   */
  protected setAspectRatio(aspectRatio: AspectRatioSelectOption) {
    this.selectAspectRatio = aspectRatio
    this.setFreeModeSelectionAspectRatio(aspectRatio.value)
  }

  /**
   * This function is called to set a selection aspect ratio in Free Mode. It will update the boundaries accordingly.
   */
  private setFreeModeSelectionAspectRatio(aspectRatio: number) {
    this.freeModeSelectionAspectRatio = aspectRatio

    this.boundaryLeft.set(0)
    this.boundaryTop.set(0)

    if (!aspectRatio) {
      this.boundaryRight.set(this.cropperWidth())
      this.boundaryBottom.set(this.cropperHeight())
      this.keepAspectRatio = false
      return
    }

    this.aspectRatio.set(aspectRatio)
    this.keepAspectRatio = true

    if (this.cropperHeight() * aspectRatio > this.cropperWidth()) {
      this.boundaryRight.set(this.cropperWidth())
      this.boundaryBottom.set(this.cropperWidth() / aspectRatio)
    } else {
      this.boundaryRight.set(this.cropperHeight() * aspectRatio)
      this.boundaryBottom.set(this.cropperHeight())
    }
  }

  /**
   * This function is called to reset the selection aspect ratio in Free Mode. It will reset the boundaries accordingly.
   */
  private resetFreeModeSelectionAspectRatio() {
    this.aspectRatio.set(this.cropperWidth() / this.cropperHeight())
    this.boundaryRight.set(this.cropperWidth())
    this.boundaryBottom.set(this.cropperHeight())
    this.freeModeSelectionAspectRatio = this.cropperWidth() / this.cropperHeight()
  }
}
