import { inject, Injectable } from '@angular/core'
import { BehaviorSubject, distinctUntilChanged, firstValueFrom, Observable } from 'rxjs'
import { Brick } from '../../data/models/brick'
import { WsEmailElementWrapperComponent } from '../../modules/block-components/ws-email-element-wrapper/ws-email-element-wrapper.component'
import { EmailService } from '../email.service'
import { WsEmailPlaceholderVariable } from '../../data/types/ws-email-placeholder-variable'
import { JSONObject, JSONValue } from '../../data/types/ws-json'
import { TestData } from '../../data/models/brick.types'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { PlaceholderVariableSelectionComponent } from '../../../../../ws/core-library/src/lib/forms/text-editor-new/variable-selection/placeholder-variable-selection.component'

@Injectable({
  providedIn: 'root'
})
export class EditorStateService {
  private selectedElementSubject = new BehaviorSubject<WsEmailElementWrapperComponent | null>(null)
  selectedElement$: Observable<WsEmailElementWrapperComponent | null> = this.selectedElementSubject.asObservable().pipe(
    distinctUntilChanged() // Only emit if the selected element changes
  )

  private subject: BehaviorSubject<{ [p: string]: string | number }> = new BehaviorSubject<{
    [p: string]: string | number
  }>({})

  styles: Observable<{ [p: string]: string | number }> = this.subject.asObservable()

  private wrapperComponents: Map<string, WsEmailElementWrapperComponent> = new Map()
  element: Brick | undefined = undefined

  showGuidelines = false

  testData: TestData = {}

  allPlaceholderVariables: WsEmailPlaceholderVariable[] = []
  placeholderVariablesWithValue: WsEmailPlaceholderVariable[] = []
  predefinedPlaceholderVariables: WsEmailPlaceholderVariable[] = []

  private emailService: EmailService = inject(EmailService)
  private dialog: MatDialog = inject(MatDialog)

  selectElement(element: Brick, scrollIntoView = false) {
    if (this.preventElementSelection(element)) return
    if (this.wrapperComponents.has(element.id)) {
      this.setSelectedElement(this.wrapperComponents.get(element.id)!)
    }
    if (scrollIntoView) {
      this.selectedElementSubject.value?.el.nativeElement.scrollIntoView({ behavior: 'instant', block: 'center' })
    }
  }

  duplicateElement(element: Brick) {
    if (element && this.wrapperComponents.has(element.id)) {
      this.wrapperComponents.get(element.id)?.duplicateElement()
    }
  }

  deleteElement(element: Brick) {
    if (element) {
      this.wrapperComponents.get(element.id)?.removeElement()
    }
  }

  hasRegisteredComponentOfElement(element: Brick) {
    return this.wrapperComponents.has(element.id)
  }

  hasRegisteredComponent(component: WsEmailElementWrapperComponent) {
    return this.wrapperComponents.has(component.element?.id || '')
  }

  registerComponent(component: WsEmailElementWrapperComponent) {
    if (component.element) {
      this.wrapperComponents.set(component.element?.id, component)
    }
  }

  unregisterComponent(component: WsEmailElementWrapperComponent) {
    if (component.element) {
      this.wrapperComponents.delete(component.element.id)
    }
  }

  setSelectedElement(selectedElement: WsEmailElementWrapperComponent): void {
    if (this.preventElementSelection(selectedElement.element)) return
    const lastSelectedElement = this.selectedElementSubject.value
    if (lastSelectedElement && lastSelectedElement != selectedElement) {
      this.unselectSelectedElement()
    }
    if (lastSelectedElement != selectedElement) {
      selectedElement.addLabelToElement()
      this.element = selectedElement.element
      this.selectedElementSubject.next(selectedElement)
    }
  }

  unselectSelectedElement() {
    if (this.selectedElementSubject.value !== null) {
      this.selectedElementSubject.value.removeLabelFromElement()
    }
    this.selectedElementSubject.next(null)
    this.element = undefined
  }

  updateViewOfAllElements() {
    this.wrapperComponents.forEach((component: WsEmailElementWrapperComponent) => {
      component.triggerElementComponentChangeDetection()
    })
  }

  updateViewOfSelectedElement(updateParent = false, updateChildren = false) {
    this.selectedElementSubject.value?.triggerElementComponentChangeDetection()
    if (updateParent) {
      const parentElement = this.selectedElementSubject.value?.parent
      if (parentElement) {
        this.wrapperComponents.get(parentElement.id)?.triggerElementComponentChangeDetection()
      }
    }
    if (updateChildren) {
      this.selectedElementSubject.value?.element?.getChildren()?.forEach((child) => {
        this.wrapperComponents.get(child.id)?.triggerElementComponentChangeDetection()
      })
    }
  }

  /**
   * Prevents deselect of  selected text/button element when marking text
   * and element to select is a section
   */
  preventElementSelection(elementToSelect?: Brick) {
    const selection = window.getSelection()
    return (
      (elementToSelect?.type || '') === 'Section' &&
      ['Text', 'Button'].includes(this.selectedElementSubject?.value?.element?.type || '') &&
      (selection?.toString() || '').length > 0
    )
  }

  /**
   * Opens a dialog to insert a placeholder variable with configurable options.
   * @private
   * @returns An observable of the dialog's result.
   */
  async openInsertPlaceholderDialog(
    filterCondition: (variable: WsEmailPlaceholderVariable) => boolean
  ): Promise<WsEmailPlaceholderVariable> {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true
    dialogConfig.minWidth = '25vw'

    dialogConfig.data = this.allPlaceholderVariables.filter(filterCondition)

    const dialogRef = this.dialog.open(PlaceholderVariableSelectionComponent, dialogConfig)

    return await firstValueFrom(dialogRef.afterClosed())
  }

  /**
   * Loads placeholder variables for the editor.
   * @param testData
   */
  loadPlaceholderVariables(testData: TestData): void {
    this.testData = testData
    this.emailService.getPlaceholderVariables().subscribe((variables) => {
      this.predefinedPlaceholderVariables = variables
      this.allPlaceholderVariables = this.mergePlaceholders(variables, testData)
      this.placeholderVariablesWithValue = this.allPlaceholderVariables.filter(
        (variable) => variable.value !== undefined
      )
    })
  }

  /**
   * Merges predefined and custom placeholders.
   * @param placeholders - Predefined placeholders.
   * @param variables - Incoming template data (custom variables).
   * @returns Array of merged placeholders.
   */
  private mergePlaceholders(
    placeholders: WsEmailPlaceholderVariable[],
    variables: TestData
  ): WsEmailPlaceholderVariable[] {
    const usedIdentifiers = new Set(placeholders.map((p) => p.identifier))
    const mergedPlaceholders = placeholders.map((placeholder) => this.createPlaceholder(placeholder, variables))
    const additionalFields = this.createCustomFields(variables, usedIdentifiers)
    return [...mergedPlaceholders, ...additionalFields]
  }

  /**
   * Creates a placeholder by merging it with template data.
   * @param placeholder - The predefined placeholder.
   * @param variables - The incoming template data.
   * @returns Merged placeholder.
   */
  private createPlaceholder(
    placeholder: WsEmailPlaceholderVariable,
    variables: JSONObject
  ): WsEmailPlaceholderVariable {
    const value = variables[placeholder.identifier]
    return {
      ...placeholder,
      value: typeof value === 'object' ? JSON.stringify(value) : value
    }
  }

  /**
   * Creates additional fields from the incoming data that are not predefined.
   * @param variables - The incoming template data.
   * @param usedIdentifiers - Set of predefined identifiers to filter out.
   * @returns Array of custom fields.
   */
  private createCustomFields(variables: JSONObject, usedIdentifiers: Set<string>): WsEmailPlaceholderVariable[] {
    return Object.keys(variables)
      .filter((key) => !usedIdentifiers.has(key))
      .map((key) => this.createCustomPlaceholder(key, variables[key]))
  }

  /**
   * Creates a custom placeholder for an additional field.
   * @param key - The key of the custom field.
   * @param value - The value of the custom field.
   * @returns A custom placeholder object.
   */
  private createCustomPlaceholder(key: string, value: JSONValue): WsEmailPlaceholderVariable {
    return {
      identifier: key,
      label: { en: key, de: key },
      value: typeof value === 'object' ? JSON.stringify(value) : value,
      isCustom: true
    }
  }
}
