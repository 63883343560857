import { Component, inject, Input, OnInit } from '@angular/core'
import { SwUpdate, VersionEvent } from '@angular/service-worker'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { VersionUpdateDialogComponent } from '../version-update-dialog/version-update-dialog.component'
import { UpdateService } from '../update.service'

@Component({
  selector: 'app-version-update',
  templateUrl: './version-update.component.html',
  styleUrls: ['./version-update.component.scss']
})
export class VersionUpdateComponent implements OnInit {
  @Input() updateCheckInterval = 60000
  @Input() forceUpdate = true

  confirmationDialogToUpdateAppVisible = false

  private swUpdate: SwUpdate = inject(SwUpdate)
  protected updateService: UpdateService = inject(UpdateService)
  public dialog: MatDialog = inject(MatDialog)

  constructor() {
    this.swUpdate.versionUpdates.subscribe((versionEvent: VersionEvent) => {
      if (versionEvent.type !== 'NO_NEW_VERSION_DETECTED') {
        console.log('New version event from service worker: ', versionEvent)
      }
    })
  }

  ngOnInit() {
    this.startCheckingForAppUpdates()
  }

  startCheckingForAppUpdates() {
    if (this.swUpdate.isEnabled) {
      this.checkForVersionUpdates()
    }
  }

  checkForVersionUpdates = () => {
    this.swUpdate
      .checkForUpdate()
      .then((newVersionFoundAndReadyToBeActivated: any) => {
        if (this.updateCheckInterval > 0) {
          // do not automatically check for new version if interval is set to zero
          setTimeout(() => {
            this.checkForVersionUpdates()
          }, this.updateCheckInterval)
        }

        if (newVersionFoundAndReadyToBeActivated) {
          this.updateService.oldVersionNoticeVisible = true
        }
      })
      .catch((e: any) => {
        console.log('failed to check for update', e)
      })
  }

  applyUpdateAndReloadPage() {
    this.updateService.applyUpdateAndReloadPage().then(() => {
      this.updateService.oldVersionNoticeVisible = false
    })
  }

  showConfirmationDialogToUpdateApp() {
    if (this.confirmationDialogToUpdateAppVisible) {
      return
    }

    const dialogRef: MatDialogRef<VersionUpdateDialogComponent> = this.dialog.open(VersionUpdateDialogComponent, {
      data: { forceUpdate: this.forceUpdate }
    })
    this.confirmationDialogToUpdateAppVisible = true

    dialogRef.afterClosed().subscribe((acceptUpdate) => {
      if (acceptUpdate) {
        this.handleAcceptNewVersion()
      } else {
        this.handleRejectNewVersion()
      }
      this.confirmationDialogToUpdateAppVisible = false
    })
  }

  handleAcceptNewVersion() {
    this.applyUpdateAndReloadPage()
  }

  handleRejectNewVersion() {
    this.updateService.oldVersionNoticeVisible = true
  }
}
