<ws-sidebar
  (showSidebarChange)="onShowDialogChange($event)"
  [(showSidebar)]="showDialog"
  class="ws-email-select-image-dialog__sidebar"
  sidebarTitle="{{ 'template.image.select' | translate }}"
>
  <ng-template #sidebarContent>
    <div>
      @if (imageSelectDialogState === ImageSelectDialogStates.Upload) {
        <div>
          <ws-advanced-file-upload
            #fileUpload
            (fileSelected)="onFileSelected($event)"
            [allowedTypes]="['image/jpeg', 'image/png']"
            (fileSelectionError)="handleFileSelectionError($event)"
          ></ws-advanced-file-upload>
          <ws-button
            buttonClass="w-full justify-center"
            class="mt-4 mr-2 bg-darkgrey block text-white"
            (click)="imageSelectDialogState = ImageSelectDialogStates.Selection"
            (keydown)="imageSelectDialogState = ImageSelectDialogStates.Selection"
          >
            <ws-icon>arrow_back</ws-icon>
            {{ 'back' | translate }}
          </ws-button>
          <ws-button
            buttonClass="w-full justify-center"
            class="mt-4 mr-2 bg-accent block text-white"
            (click)="selectedFile ? openImageEditor(selectedFile) : ''"
            (keydown)="selectedFile ? openImageEditor(selectedFile) : ''"
            [disabled]="!selectedFile"
          >
            <ws-icon>edit</ws-icon>
            {{ 'template.image.edit' | translate }}
          </ws-button>
          <ws-button
            buttonClass="w-full justify-center"
            class="mt-4 bg-accent block text-white"
            (click)="onUploadImage()"
            (keydown)="onUploadImage()"
            [disabled]="!selectedFile"
            [loading]="uploading"
          >
            <ws-icon>save</ws-icon>
            {{ 'template.image.select' | translate }}
          </ws-button>
        </div>
      } @else if (imageSelectDialogState === ImageSelectDialogStates.LinkInput) {
        <div class="flex items-center w-full ml-4">
          <ws-form-input
            placeholder="https://example.com/image.png"
            class="w-full"
            label="{{ 'template.element.imageUrl' | translate }}"
            [formControl]="linkInputFormControl"
          ></ws-form-input>

          <div class="ml-4 pt-5">
            <ws-icon
              size="28px"
              class="cursor-pointer"
              matTooltip="{{ 'ws.forms.textEditor.tooltip.selectPlaceholderVariable' | translate }}"
              (click)="openInsertPlaceholderDialog()"
              >data_object</ws-icon
            >
          </div>
        </div>

        <ws-button
          buttonClass="w-full justify-center"
          class="mt-4 mr-2 bg-darkgrey block text-white"
          (click)="imageSelectDialogState = ImageSelectDialogStates.Selection"
          (keydown)="imageSelectDialogState = ImageSelectDialogStates.Selection"
        >
          <ws-icon>arrow_back</ws-icon>
          {{ 'back' | translate }}
        </ws-button>
        <ws-button
          buttonClass="w-full justify-center"
          class="mt-4 bg-accent block text-white"
          (click)="onLinkSelected()"
          (keydown)="onLinkSelected()"
        >
          <ws-icon>save</ws-icon>
          {{ 'template.image.select' | translate }}
        </ws-button>
      } @else {
        <div class="menu-view__search-field mb-4 border rounded-lg overflow-hidden grow">
          <ws-simple-input
            [clearable]="true"
            [formControl]="searchFormControl"
            icon="search"
            placeholder="{{ 'ws.forms.placeholder.search' | translate }}"
            style="margin: 0"
          ></ws-simple-input>
        </div>
        <div class="flex mb-6 items-center justify-between">
          <div>
            <ws-button
              buttonClass="w-fit justify-center"
              class="text-darkgrey"
              (click)="onDeleteImages()"
              (keydown)="onDeleteImages()"
            >
              <ws-icon>delete</ws-icon>
            </ws-button>
          </div>
          <div class="w-fit flex-none">
            <ws-button
              (click)="handleUseLinkButtonClickAction()"
              (keydown)="handleUseLinkButtonClickAction()"
              buttonClass="w-full justify-center"
              class="mr-2 block text-darkgrey"
            >
              <ws-icon>link</ws-icon>
              <div class="max-xl:hidden">{{ 'template.editor.image.useLink' | translate }}</div>
            </ws-button>

            <ws-button
              (click)="imageSelectDialogState = ImageSelectDialogStates.Upload"
              (keydown)="imageSelectDialogState = ImageSelectDialogStates.Upload"
              buttonClass="w-full justify-center"
              class="bg-accent block text-white"
            >
              <ws-icon>upload</ws-icon>
              <div class="max-xl:hidden">{{ 'template.editor.image.upload.upload' | translate }}</div>
            </ws-button>
          </div>
        </div>
        <div class="grid grid-cols-3 md:grid-cols-4 gap-x-4 gap-y-6" [formGroup]="selectedImagesFormGroup">
          @if (shownImages.length === 0) {
            <div class="col-span-full text-center text-darkgrey w-full border rounded-lg py-4">
              {{ 'template.image.noImagesFound' | translate }}
            </div>
          }
          @for (image of shownImages; track image) {
            <div>
              <div
                class="ws-email-select-image-dialog__sidebar__image-wrapper aspect-[4/3] bg-gray-50 items-center justify-center cursor-pointer rounded-lg overflow-hidden border hover:ring hover:ring-accent relative group"
                (click)="!imageDeleteLoadingState[image.identifier] ? onSelectImage(image.url) : ''"
                (keydown)="!imageDeleteLoadingState[image.identifier] ? onSelectImage(image.url) : ''"
              >
                <img
                  class="object-contain w-full h-full"
                  src="{{ image.url }}"
                  alt="{{ image.filename }}"
                  height="100"
                  width="100"
                />
                @if (imageDeleteLoadingState[image.identifier]) {
                  <div class="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center">
                    <mat-spinner diameter="40" class="text-accent"></mat-spinner>
                  </div>
                }
                <div
                  class="absolute invisible group-hover:visible w-full h-full bg-darkgrey bg-opacity-10 top-0 left-0"
                >
                  <div class="grid justify-items-end w-full">
                    <ws-button
                      class="z-30 text-white hover:text-gray-500"
                      [flat]="true"
                      (click)="onDeleteImage($event, image.identifier)"
                      (keydown)="onDeleteImage($event, image.identifier)"
                      [disabled]="imageDeleteLoadingState[image.identifier] || false"
                    >
                      <ws-icon>delete</ws-icon>
                    </ws-button>
                  </div>
                </div>
                <div class="absolute w-full h-full top-0 left-0">
                  <ws-form-checkbox
                    [formControlName]="image.identifier"
                    class="text-white pl-0"
                    (click)="onImageMultiselect($event)"
                    (keydown)="onImageMultiselect($event)"
                    [disabled]="imageDeleteLoadingState[image.identifier] || false"
                  ></ws-form-checkbox>
                </div>
              </div>
              <div
                class="text-sm text-[--mdc-outlined-text-field-label-text-color] pt-2 break-all line-clamp-3"
                title="{{ image.filename }}"
              >
                {{ image.filename }}
              </div>
            </div>
          }
        </div>
      }
    </div>
  </ng-template>
</ws-sidebar>
