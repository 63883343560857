<h1 mat-dialog-title>{{ 'ws.update.title' | translate }}</h1>
<div mat-dialog-content>{{ 'ws.update.message' | translate }}</div>
<div mat-dialog-actions align="center">
  @if (!data.forceUpdate) {
    <button mat-button (click)="onNoClick()">
      {{ 'ws.update.updateLater' | translate }}
    </button>
  }
  <button mat-flat-button [mat-dialog-close]="true" cdkFocusInitial>
    {{ 'ws.update.updateNow' | translate }}
  </button>
</div>
