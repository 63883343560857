import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core'
import { EditorStateService } from '../../../services/util/editor-state.service'
import { Brick } from '../../../data/models/brick'
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { SelectOption } from '@ws-core/core-library'
import { Subscription } from 'rxjs'
import { Text } from '../../../data/models/text'
import { PlaceholderTypes, WsEmailPlaceholderVariable } from '../../../data/types/ws-email-placeholder-variable'

export const fontOptions: SelectOption[] = [
  { label: 'Arial', value: 'Arial, sans-serif' },
  { label: 'PT Sans', value: 'PT Sans, sans-serif' },
  { label: 'Roboto', value: 'Roboto, sans-serif' },
  { label: 'Verdana', value: 'Verdana, sans-serif' },
  { label: 'Helvetica', value: 'Helvetica, sans-serif' },
  { label: 'Times New Roman', value: 'Times New Roman, sans-serif' },
  { label: 'Garamond', value: 'Garamond, sans-serif' },
  { label: 'Raleway', value: 'Raleway, sans-serif' },
  { label: 'Ubuntu', value: 'Ubuntu, Helvetica, Arial, sans-serif' },
  { label: 'Open Sans', value: 'Open Sans, Arial, Helvetica, sans-serif' },
  { label: 'Georgia', value: 'Georgia, sans-serif' }
]

@Component({
  selector: 'app-ws-email-text-settings',
  templateUrl: './ws-email-text-settings.component.html',
  styleUrl: './ws-email-text-settings.component.scss'
})
export class WsEmailTextSettingsComponent implements OnChanges, OnDestroy {
  @Input() selectedElement?: Brick
  @Output() selectedElementTextPropertyChange = new EventEmitter()
  timeoutPropertyChange?: number

  colorValidator: ValidatorFn = Validators.pattern(/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$|^\$\{[a-zA-Z_][a-zA-Z0-9_-]*}$/)
  formSubscription: Subscription | undefined
  textSettingsForm: FormGroup = new FormGroup({})
  formIsInitialized: boolean = false
  fontOptions: SelectOption[] = fontOptions
  readonly fontSizeH1: string = '22'
  readonly fontSizeH2: string = '20'
  readonly fontSizeH3: string = '18'
  readonly fontSizeH4: string = '16'

  constructor(private editorStateService: EditorStateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['selectedElement'] &&
      changes['selectedElement'].currentValue !== changes['selectedElement'].previousValue
    ) {
      if (this.formSubscription) {
        this.formSubscription.unsubscribe()
      }
      this.formIsInitialized = false
      this.initTextSettingsForm()
    }
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe()
    }
  }

  reset(property: string) {
    this.textSettingsForm.patchValue({ [property]: '' })
  }

  resetFields(properties: string[]) {
    properties.forEach((property) => this.reset(property))
  }

  private initTextSettingsForm() {
    if (this.selectedElement) {
      switch (this.selectedElement.type) {
        case 'Button':
          this.textSettingsForm = this.createFormGroupForButton()
          break
        default:
          this.textSettingsForm = this.createFormGroupForText()
      }

      this.formSubscription = this.textSettingsForm.valueChanges.subscribe((value) => {
        Object.keys(value).forEach((field) => {
          if (this.textSettingsForm.get(field)?.errors || this.selectedElement === undefined) return
          switch (field) {
            case 'font-size': {
              if (value[field] === 'null' || !value[field]) {
                this.selectedElement.styles[field] = '14px'
                return
              }
              this.selectedElement.styles[field] = value[field] + 'px'
              break
            }
            case 'color': {
              const color = value[field]
              if (this.selectedElement.type === 'Button' && (color === 'null' || !color)) {
                this.selectedElement.styles[field] = '#ffffff'
                return
              }
              this.selectedElement.styles[field] = color
              break
            }
            default: {
              if (!value[field]) {
                delete this.selectedElement.styles[field]
                return
              }
              this.selectedElement.styles[field] = value[field]
            }
          }
        })
        this.editorStateService.updateViewOfSelectedElement()
        clearTimeout(this.timeoutPropertyChange)
        this.timeoutPropertyChange = setTimeout(() => {
          this.selectedElementTextPropertyChange.emit()
        }, 500)
      })
    }
    this.formIsInitialized = true
  }

  atLeastOneFieldExists(fieldNames: string[]) {
    return fieldNames.some((fieldName: string) => this.fieldExists(fieldName))
  }

  fieldExists(fieldName: string) {
    return !!this.textSettingsForm.get(fieldName)
  }

  private createFormGroupForText() {
    const formControlFields: { [key: string]: FormControl } = {
      'font-size': new FormControl(this.selectedElement?.styles['font-size']?.toString().replace('px', '') || ''),
      'font-family': new FormControl(this.selectedElement?.styles['font-family'] || ''),
      'line-height': new FormControl(this.selectedElement?.styles['line-height'] || 1),
      'text-transform': new FormControl(this.selectedElement?.styles['text-transform'] || '')
    }
    if ((this.selectedElement as Text).isHeading()) {
      formControlFields['color'] = new FormControl(this.selectedElement?.styles['color'] || '', this.colorValidator)
    }
    return new FormGroup(formControlFields)
  }

  private createFormGroupForButton() {
    const formControlFields = {
      'font-size': new FormControl(this.selectedElement?.styles['font-size']?.toString().replace('px', '')),
      'font-family': new FormControl(this.selectedElement?.styles['font-family'] || ''),
      color: new FormControl(this.selectedElement?.styles['color']?.toString() || '#ffffff', this.colorValidator)
    }
    return new FormGroup(formControlFields)
  }

  /**
   * Opens the dialog to insert a placeholder variable
   * @param color
   */
  onPlaceholderVariableClick(color: string) {
    this.editorStateService
      .openInsertPlaceholderDialog((variable) => variable.type === PlaceholderTypes.COLOR)
      .then((response: WsEmailPlaceholderVariable) => {
        if (response) {
          this.textSettingsForm.patchValue({ [color]: '${' + response.identifier + '}' })
        }
      })
  }
}
