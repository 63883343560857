<div [class.hide-subscript]="!hint" class="ws-text-editor w-full">
  @if (editor) {
    @if (useMenu) {
      @if (!useFloatingMenu) {
        <ws-form-text-editor-menu
          [editor]="editor"
          [toolbar]="toolbar"
          [showEmojiPicker]="showEmojiPicker"
          [showTextColorPicker]="showTextColorPicker"
          [placeholderVariables]="placeholderVariables"
          class="ws-text-editor_menu bg-white rounded-t-lg"
        ></ws-form-text-editor-menu>
      } @else {
        <div class="editor">
          <ws-form-text-editor-floating-menu
            #floatingMenu
            class="ws-form-text-editor-floating-menu"
            [editor]="editor"
            [toolbar]="toolbar"
            [showEmojiPicker]="showEmojiPicker"
            [showTextColorPicker]="showTextColorPicker"
            [placeholderVariables]="placeholderVariables"
          ></ws-form-text-editor-floating-menu>
        </div>
      }
    }
    <ngx-editor
      class="ws-text-editor_editor"
      [ngClass]="{ 'floating-menu': useFloatingMenu }"
      [editor]="editor"
      [(ngModel)]="content"
      outputFormat="html"
      (ngModelChange)="onContentChange()"
    ></ngx-editor>
  }
</div>
