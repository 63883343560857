import { Component, HostBinding, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRippleModule } from '@angular/material/core'

@Component({
  selector: 'ws-button',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatProgressSpinnerModule, MatRippleModule],
  templateUrl: './ws-button.component.html',
  styleUrl: './ws-button.component.scss'
})
export class WsButtonComponent {
  @Input() public loading = false
  @Input() public disabled = false
  @Input() public type: 'button' | 'submit' | 'reset' = 'button'
  @Input() public buttonClass: string | string[] = ''

  @HostBinding('class.ws-button--flat') @Input() public flat = false
  @HostBinding('class.ws-button--dense') @Input() public dense = false

  @HostBinding('class.ws-button--disabled') get disableButton() {
    return this.disabled || this.loading
  }
}
