<div class="ws-image-cropper flex justify-center flex-wrap">
  <div class="ws-image-cropper__container flex flex-wrap w-full relative justify-center flex-col">
    <div class="ws-image-cropper__buttons flex justify-end">
      @if (freeMode() && aspectRatioSelectOptions().length > 0) {
        <button
          class="ws-image-cropper__button mdc-icon-button mat-primary mat-mdc-button-base mr-auto flex flex-nowrap w-fit py-3 pr-3"
          [matMenuTriggerFor]="aspectRatioSelect"
        >
          <mat-icon>{{ selectAspectRatio.icon }}</mat-icon>
          @if (selectAspectRatio.translations) {
            <span class="ml-1">{{ selectAspectRatio.translations | localized }}</span>
          } @else {
            <span class="ml-1">{{ selectAspectRatio.label }}</span>
          }
        </button>
      }
      @if (!freeMode()) {
        <button
          class="ws-image-cropper__button mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base"
          (click)="zoom(0.05)"
        >
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button
          class="ws-image-cropper__button mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base"
          (click)="zoom(-0.05)"
        >
          <mat-icon>zoom_out</mat-icon>
        </button>
      }
      <button
        class="ws-image-cropper__button mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base"
        (click)="rotate(90)"
      >
        <mat-icon>rotate_90_degrees_cw</mat-icon>
      </button>
      <button
        class="ws-image-cropper__button mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base"
        (click)="rotate(-90)"
      >
        <mat-icon>rotate_90_degrees_ccw</mat-icon>
      </button>
      <button
        class="ws-image-cropper__button mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base"
        (click)="initImageCropper()"
      >
        <mat-icon>reset_image</mat-icon>
      </button>
    </div>
    <div
      class="ws-image-cropper__image flex relative overflow-hidden w-full"
      [id]="'container-' + canvasId"
      [class]="{ 'ws-image-cropper__image--rounded-preview': roundCropper() && !freeMode() }"
    >
      @if (imageLoaded()) {
        <canvas
          #imageCanvas
          class="ws-image-cropper__canvas cursor-move"
          [ngClass]="{ 'cursor-move': !freeMode() }"
          [id]="canvasId"
          [width]="cropperWidth()"
          [height]="cropperHeight()"
        ></canvas>
      }

      @if (freeMode()) {
        <div
          class="ws-image-cropper__corner--boundary absolute top-0 bottom-0 left-0 right-0 pointer-events-none"
          [style.--y-boundary-top]="boundaryTop() + currentDragDistanceTop() + 'px'"
          [style.--y-boundary-bottom]="boundaryBottom() + currentDragDistanceBottom() + 'px'"
          [style.--x-boundary-left]="boundaryLeft() + currentDragDistanceLeft() + 'px'"
          [style.--x-boundary-right]="boundaryRight() + currentDragDistanceRight() + 'px'"
        >
          <div
            class="ws-image-cropper__preview--boundary absolute border-dashed border-gray-800 border z-10 pointer-events-auto"
            [ngClass]="{ 'cursor-move': !movingCorner }"
            cdkDrag
            cdkDragBoundary=".ws-image-cropper__corner--boundary"
            (cdkDragEnded)="previewDragEnded($event)"
            (cdkDragMoved)="previewDragMoved($event)"
          ></div>

          <div
            class="ws-image-cropper__corner--top-left cursor-nwse-resize pointer-events-auto absolute bg-gray-800 w-2.5 h-2.5 z-10 border border-gray-400"
            cdkDrag
            cdkDragBoundary=".ws-image-cropper__corner--boundary"
            (cdkDragEnded)="edgeElementMoveEnded()"
            (cdkDragMoved)="edgeElementMoved('top-left', $event)"
            (cdkDragStarted)="edgeElementMoveStarted('top-left')"
          ></div>
          @if (!keepAspectRatio) {
            <div
              class="ws-image-cropper__edge--top-point cursor-ns-resize pointer-events-auto absolute bg-gray-800 w-2.5 h-2.5 z-10 border border-gray-400"
              cdkDrag
              cdkDragBoundary=".ws-image-cropper__corner--boundary"
              cdkDragLockAxis="y"
              (cdkDragEnded)="edgeElementMoveEnded()"
              (cdkDragMoved)="edgeElementMoved('top', $event)"
            ></div>
          }
          <div
            class="ws-image-cropper__corner--top-right cursor-nesw-resize pointer-events-auto absolute bg-gray-800 w-2.5 h-2.5 z-10 border border-gray-400"
            cdkDrag
            cdkDragBoundary=".ws-image-cropper__corner--boundary"
            (cdkDragEnded)="edgeElementMoveEnded()"
            (cdkDragMoved)="edgeElementMoved('top-right', $event)"
            (cdkDragStarted)="edgeElementMoveStarted('top-right')"
          ></div>
          @if (!keepAspectRatio) {
            <div
              class="ws-image-cropper__edge--right-point cursor-ew-resize pointer-events-auto absolute bg-gray-800 w-2.5 h-2.5 z-10 border border-gray-400"
              cdkDrag
              cdkDragBoundary=".ws-image-cropper__corner--boundary"
              cdkDragLockAxis="x"
              (cdkDragEnded)="edgeElementMoveEnded()"
              (cdkDragMoved)="edgeElementMoved('right', $event)"
            ></div>
          }
          <div
            class="ws-image-cropper__corner--bottom-right cursor-nwse-resize pointer-events-auto absolute bg-gray-800 w-2.5 h-2.5 z-10 border border-gray-400"
            cdkDrag
            cdkDragBoundary=".ws-image-cropper__corner--boundary"
            (cdkDragEnded)="edgeElementMoveEnded()"
            (cdkDragMoved)="edgeElementMoved('bottom-right', $event)"
            (cdkDragStarted)="edgeElementMoveStarted('bottom-right')"
          ></div>
          @if (!keepAspectRatio) {
            <div
              class="ws-image-cropper__edge--bottom-point cursor-ns-resize pointer-events-auto absolute bg-gray-800 w-2.5 h-2.5 z-10 border border-gray-400"
              cdkDrag
              cdkDragBoundary=".ws-image-cropper__corner--boundary"
              cdkDragLockAxis="y"
              (cdkDragEnded)="edgeElementMoveEnded()"
              (cdkDragMoved)="edgeElementMoved('bottom', $event)"
            ></div>
          }
          <div
            class="ws-image-cropper__corner--bottom-left cursor-nesw-resize pointer-events-auto absolute bg-gray-800 w-2.5 h-2.5 z-10 border border-gray-400"
            cdkDrag
            cdkDragBoundary=".ws-image-cropper__corner--boundary"
            (cdkDragEnded)="edgeElementMoveEnded()"
            (cdkDragMoved)="edgeElementMoved('bottom-left', $event)"
            (cdkDragStarted)="edgeElementMoveStarted('bottom-left')"
          ></div>
          @if (!keepAspectRatio) {
            <div
              class="ws-image-cropper__edge--left-point cursor-ew-resize pointer-events-auto absolute bg-gray-800 w-2.5 h-2.5 z-10 border border-gray-400"
              cdkDrag
              cdkDragBoundary=".ws-image-cropper__corner--boundary"
              cdkDragLockAxis="x"
              (cdkDragEnded)="edgeElementMoveEnded()"
              (cdkDragMoved)="edgeElementMoved('left', $event)"
            ></div>
          }
        </div>
      }
      @if (dragging()) {
        <div class="ws-image-cropper__helper-lines pointer-events-none absolute w-full h-full">
          <div
            class="ws-image-cropper__helper-line ws-image-cropper__helper-line--horizontal ws-image-cropper__helper-line--1 absolute"
          ></div>
          <div
            class="ws-image-cropper__helper-line ws-image-cropper__helper-line--horizontal ws-image-cropper__helper-line--2 absolute"
          ></div>
          <div
            class="ws-image-cropper__helper-line ws-image-cropper__helper-line--vertical ws-image-cropper__helper-line--3 absolute"
          ></div>
          <div
            class="ws-image-cropper__helper-line ws-image-cropper__helper-line--vertical ws-image-cropper__helper-line--4 absolute"
          ></div>
        </div>
      }
    </div>
  </div>
</div>

<mat-menu #aspectRatioSelect="matMenu">
  @for (aspectRatioSelectOption of aspectRatioSelectOptions(); track aspectRatioSelectOption.value) {
    <button
      class="ws-image-cropper__button mdc-icon-button mat-mdc-icon-button mat-primary mat-mdc-button-base mr-auto"
      (click)="setAspectRatio(aspectRatioSelectOption)"
    >
      <mat-icon>{{ aspectRatioSelectOption.icon }}</mat-icon>
      @if (aspectRatioSelectOption.translations) {
        <span>{{ aspectRatioSelectOption.translations | localized }}</span>
      } @else {
        <span>{{ aspectRatioSelectOption.label }}</span>
      }
    </button>
  }
</mat-menu>
