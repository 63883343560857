import { AppComponent } from './app.component'
import { isDevMode, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HeaderInterceptor } from './core/header.interceptor'
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module'
import { WsEmailEditorViewComponent } from './modules/editor/ws-email-editor-view/ws-email-editor-view.component'
import { WsEmailEditorComponent } from './modules/editor/ws-email-editor/ws-email-editor.component'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatCardModule } from '@angular/material/card'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatRadioModule } from '@angular/material/radio'
import { MatTableModule } from '@angular/material/table'
import { MatListModule } from '@angular/material/list'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatSliderModule } from '@angular/material/slider'
import { MatSelectModule } from '@angular/material/select'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatMenuModule } from '@angular/material/menu'
import { ClipboardModule } from '@angular/cdk/clipboard'
import { WsEmailElementSettingsComponent } from './modules/settings/ws-email-element-settings/ws-email-element-settings.component'
import {
  WsButtonComponent,
  WsFormsModule,
  WsIconComponent,
  WsNotificationsModule,
  WsPipesModule,
  WsSidebarComponent
} from '@ws-core/core-library'
import { PlatformService } from '../../../demo/src/app/platform.service'
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { WsEmailTextToolbarComponent } from './modules/block-components/ws-email-text-toolbar/ws-email-text-toolbar.component'
import { WsEmailPageSettingsComponent } from './modules/settings/ws-email-page-settings/ws-email-page-settings.component'
import { SafeHtmlPipe } from './pipes/safe-html-pipe.pipe'
import { WsEmailTextSettingsComponent } from './modules/settings/ws-email-text-settings/ws-email-text-settings.component'
import { MatExpansionModule } from '@angular/material/expansion'
import { WsEmailLayersComponent } from './modules/editor/ws-email-layers/ws-email-layers.component'
import { ResizableDirective } from './directives/resizable.directive'
import { WsEmailDefaultBlocksComponent } from './modules/editor/ws-email-default-blocks/ws-email-default-blocks.component'
import { WsEmailViewComponent } from './modules/editor/ws-email-view/ws-email-view.component'
import { WsEmailAdminComponent } from './modules/editor/ws-email-admin/ws-email-admin.component'
import { MatChipsModule } from '@angular/material/chips'
import { WsEmailSocialComponent } from './modules/block-components/ws-email-social/ws-email-social.component'
import { WsEmailSocialElementComponent } from './modules/block-components/ws-email-social-element/ws-email-social-element.component'
import { WsEmailElementWrapperComponent } from './modules/block-components/ws-email-element-wrapper/ws-email-element-wrapper.component'
import { WsEmailImageComponent } from './modules/block-components/ws-email-image/ws-email-image.component'
import { WsEmailElementComponent } from './modules/block-components/ws-email-element/ws-email-element.component'
import { WsEmailSectionComponent } from './modules/block-components/ws-email-section/ws-email-section.component'
import { WsEmailHeroComponent } from './modules/block-components/ws-email-hero/ws-email-hero.component'
import { WsEmailColumnComponent } from './modules/block-components/ws-email-column/ws-email-column.component'
import { WsEmailTextComponent } from './modules/block-components/ws-email-text/ws-email-text.component'
import { WsEmailSpacerComponent } from './modules/block-components/ws-email-spacer/ws-email-spacer.component'
import { WsEmailDividerComponent } from './modules/block-components/ws-email-divider/ws-email-divider.component'
import { WsEmailButtonComponent } from './modules/block-components/ws-email-button/ws-email-button.component'
import { WsEmailEditTestDataDialogComponent } from './modules/dialogs/ws-email-edit-test-data-dialog/ws-email-edit-test-data-dialog.component'
import { WsEmailCreateTemplateDialogComponent } from './modules/dialogs/ws-email-create-template-dialog/ws-email-create-template-dialog.component'
import { WsEmailAiDialogComponent } from './modules/dialogs/ws-email-ai-dialog/ws-email-ai-dialog.component'
import { WsEmailDeleteEmailBlockDialogComponent } from './modules/dialogs/ws-email-delete-email-block-dialog/ws-email-delete-email-block-dialog.component'
import { WsEmailDeleteTemplateDialogComponent } from './modules/dialogs/ws-email-delete-template-dialog/ws-email-delete-template-dialog.component'
import { IframeDirective } from './directives/iframe.directive'
import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion'
import { WsEmailAddVariableDialogComponent } from './modules/dialogs/ws-email-add-variable-dialog/ws-email-add-variable-dialog.component'
import { WsEmailSelectImageDialogComponent } from './modules/dialogs/ws-email-select-image-dialog/ws-email-select-image-dialog.component'
import { NgOptimizedImage } from '@angular/common'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { CustomMissingTranslationHandler } from '../../../pos/src/app/shared/custom-missing-translation-handler'
import { WsEmailColumnGridComponent } from './modules/settings/ws-email-element-settings-column-grid/ws-email-column-grid.component'
import { ImageCropperComponent } from 'ngx-image-cropper'
import { WsEmailEditImageDialogComponent } from './modules/dialogs/ws-email-edit-image-dialog/ws-email-edit-image-dialog.component'
import { MatProgressSpinner } from '@angular/material/progress-spinner'
import { UpdateModule } from './modules/update/update.module'
import { ServiceWorkerModule } from '@angular/service-worker'

@NgModule({
  declarations: [
    AppComponent,
    WsEmailEditorViewComponent,
    WsEmailEditorComponent,
    WsEmailElementComponent,
    WsEmailSectionComponent,
    WsEmailHeroComponent,
    WsEmailColumnComponent,
    WsEmailSocialComponent,
    WsEmailSocialElementComponent,
    WsEmailTextComponent,
    WsEmailImageComponent,
    WsEmailSpacerComponent,
    WsEmailDividerComponent,
    WsEmailButtonComponent,
    WsEmailElementSettingsComponent,
    WsEmailTextToolbarComponent,
    WsEmailElementWrapperComponent,
    WsEmailPageSettingsComponent,
    WsEmailLayersComponent,
    WsEmailTextSettingsComponent,
    WsEmailDefaultBlocksComponent,
    WsEmailEditTestDataDialogComponent,
    WsEmailViewComponent,
    WsEmailAdminComponent,
    WsEmailCreateTemplateDialogComponent,
    WsEmailAiDialogComponent,
    WsEmailDeleteEmailBlockDialogComponent,
    WsEmailDeleteTemplateDialogComponent,
    WsEmailAddVariableDialogComponent,
    WsEmailSelectImageDialogComponent,
    WsEmailEditImageDialogComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      extend: true,
      missingTranslationHandler: isDevMode()
        ? {
            provide: MissingTranslationHandler,
            useClass: CustomMissingTranslationHandler
          }
        : undefined
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    ReactiveFormsModule,
    WsFormsModule,
    FormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatSidenavModule,
    MatTabsModule,
    MatTableModule,
    MatListModule,
    MatCheckboxModule,
    MatSliderModule,
    MatSelectModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatMenuModule,
    ClipboardModule,
    SafeHtmlPipe,
    WsButtonComponent,
    MatExpansionModule,
    ResizableDirective,
    WsIconComponent,
    WsNotificationsModule,
    MatChipsModule,
    IframeDirective,
    CdkAccordion,
    CdkAccordionItem,
    WsPipesModule,
    CdkAccordionItem,
    NgOptimizedImage,
    WsFormsModule,
    WsSidebarComponent,
    ImageCropperComponent,
    MatProgressSpinner,
    WsSidebarComponent,
    WsEmailColumnGridComponent,
    UpdateModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: 'PlatformService', useClass: PlatformService },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined')
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  let apiBaseUrl = 'https://labels.waldhart.at/wscore/email/'
  const hostnameSplit: string[] = location.hostname.split('.')
  // if hostname starts with localhost, use no explicit base url, so that the request is sent to the same origin and the proxy can handle it
  if (hostnameSplit[0] === 'localhost') {
    apiBaseUrl = '/wscore/email/'
  }

  return new TranslateHttpLoader(http, apiBaseUrl, '.json')
}
