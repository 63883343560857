import { Component, inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

interface VersionUpdateDialogData {
  forceUpdate: boolean
}

@Component({
  selector: 'app-version-update-dialog',
  templateUrl: './version-update-dialog.component.html',
  styleUrls: ['./version-update-dialog.component.scss']
})
export class VersionUpdateDialogComponent {
  public dialogRef: MatDialogRef<VersionUpdateDialogComponent> = inject(MatDialogRef)
  public data: VersionUpdateDialogData = inject(MAT_DIALOG_DATA)

  onNoClick() {
    this.dialogRef.close()
  }
}
