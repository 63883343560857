<div class="p-2">
  <div mat-dialog-title>
    {{ 'ws.forms.textEditor.addPlaceholderVariable' | translate }}
  </div>

  <mat-dialog-content class="ws-email-test-data-dialog__content-wrapper">
    <ws-form-select
      [placeholder]="'ws.forms.textEditor.selectPlaceholderVariable' | translate"
      [filter]="true"
      [(ngModel)]="selectedPlaceholderVariable"
      [options]="placeholderSelectOptions"
    >
    </ws-form-select>
  </mat-dialog-content>

  <div class="flex flex-wrap justify-end">
    <mat-dialog-actions>
      <ws-button (click)="close()" [flat]="true">{{ 'cancel' | translate }}</ws-button>
      <ws-button (click)="insertVariable()" [flat]="true" class="text-white bg-accent">
        {{ 'ws.forms.textEditor.tooltip.insert' | translate }}</ws-button
      >
    </mat-dialog-actions>
  </div>
</div>
