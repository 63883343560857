<button
  class="ws-button select-none h-full"
  [ngClass]="buttonClass"
  [type]="type"
  matRipple
  [matRippleDisabled]="disabled"
>
  @if (loading) {
    <mat-spinner [diameter]="16" class="mr-2"></mat-spinner>
  }
  <ng-content></ng-content>
</button>
