import { Component, Inject, inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { FormControl, FormGroup } from '@angular/forms'
import { WsEmailPlaceholderVariable } from '../../../data/types/ws-email-placeholder-variable'
import { WsEmailAddVariableDialogComponent } from '../ws-email-add-variable-dialog/ws-email-add-variable-dialog.component'
import { WsNotificationsService } from '@ws-core/core-library'
import { JSONObject, JSONValue } from '../../../data/types/ws-json'
import { EditorStateService } from '../../../services/util/editor-state.service'

@Component({
  selector: 'app-ws-email-edit-test-data-dialog',
  templateUrl: './ws-email-edit-test-data-dialog.component.html',
  styleUrl: './ws-email-edit-test-data-dialog.component.scss'
})
export class WsEmailEditTestDataDialogComponent implements OnInit {
  advancedViewFormControl = new FormControl(false)
  testDataFormGroup: FormGroup = new FormGroup({})
  testDataAdvancedFormControl = new FormControl('')
  predefinedVariables: WsEmailPlaceholderVariable[] = []
  placeholders: WsEmailPlaceholderVariable[] = []

  private dialog = inject(MatDialog)
  private notificationService: WsNotificationsService = inject(WsNotificationsService)
  private editorStateService = inject(EditorStateService)

  constructor(
    private dialogRef: MatDialogRef<WsEmailEditTestDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JSONObject
  ) {}

  ngOnInit(): void {
    this.initializePlaceholderValues()

    this.advancedViewFormControl.valueChanges.subscribe((value) => {
      if (value === true) {
        this.switchToAdvancedView()
      } else {
        this.switchToSimpleView()
      }
    })
  }

  switchToAdvancedView(): void {
    const formValue = this.cleanUpNestedObjects(this.testDataFormGroup.value)
    this.testDataAdvancedFormControl.patchValue(JSON.stringify(formValue, null, 2))
  }

  switchToSimpleView(): void {
    if (typeof this.testDataAdvancedFormControl.value === 'string') {
      try {
        const parsedValue = JSON.parse(this.testDataAdvancedFormControl.value)
        const preparedForForm = this.prepareDataForSimpleView(parsedValue) // Stringify nested objects for display in form
        this.testDataFormGroup.patchValue(preparedForForm)
      } catch (e) {
        console.error(e)
        this.notificationService.error('Invalid JSON format')
      }
    }
  }

  /**
   * Saves the form data by preparing it and closing the dialog.
   */
  save(): void {
    const saveData = this.prepareDataForSave()
    try {
      JSON.stringify(saveData)
    } catch (e) {
      this.notificationService.error('Invalid JSON format')
      return
    }
    this.dialogRef.close(saveData)
  }

  /**
   * Deletes a placeholder variable from both the form and the list of placeholders.
   * @param identifier - The identifier of the placeholder to delete.
   */
  deletePlaceholderVariable(identifier: string): void {
    this.placeholders = this.placeholders.filter((p) => p.identifier !== identifier)
    this.testDataFormGroup.removeControl(identifier)
    this.testDataFormGroup.removeControl(`${identifier}__key`)
  }

  /**
   * Adds or replaces a placeholder variable in the form and placeholders list.
   * @param placeholderVariable - The placeholder to add or update.
   */
  private addOrReplacePlaceholderVariable(placeholderVariable: WsEmailPlaceholderVariable): void {
    if (!this.placeholders.some((p) => p.identifier === placeholderVariable.identifier)) {
      this.placeholders.push(placeholderVariable)
    }

    this.testDataFormGroup.setControl(placeholderVariable.identifier, new FormControl(placeholderVariable.value))

    if (placeholderVariable.isCustom) {
      this.testDataFormGroup.setControl(
        `${placeholderVariable.identifier}__key`,
        new FormControl(placeholderVariable.identifier)
      )
    }
  }

  /**
   * Opens the dialog to add a new placeholder variable.
   */
  openAddVariableDialog(): void {
    const dialogRef = this.dialog.open(WsEmailAddVariableDialogComponent, {
      data: { predefinedVariables: this.predefinedVariables }
    })

    dialogRef.afterClosed().subscribe((result: WsEmailPlaceholderVariable) => {
      if (result) {
        this.addOrReplacePlaceholderVariable(result)
      }
    })
  }

  /**
   * Closes the dialog without saving.
   */
  close(): void {
    this.dialogRef.close()
  }

  /**
   * Cleans up nested objects by ensuring that nested JSON objects are not double-stringified.
   * @param data - The form value object to be cleaned up.
   * @returns A cleaned-up version of the form value with nested objects intact.
   */
  private cleanUpNestedObjects(data: JSONObject): JSONObject {
    const cleanedData = { ...data }

    Object.keys(cleanedData).forEach((key) => {
      if (typeof cleanedData[key] === 'string') {
        try {
          const parsedValue = JSON.parse(cleanedData[key] as string)
          if (typeof parsedValue === 'object') {
            cleanedData[key] = parsedValue
          }
        } catch (e) {
          // If it's not a valid JSON string, we leave it as is
        }
      }
    })

    return cleanedData
  }

  /**
   * Converts nested objects to strings so that form inputs can handle them.
   * @param data - The parsed value from the advanced JSON view.
   * @returns A version of the data where nested objects are stringified for form inputs.
   */
  private prepareDataForSimpleView(data: JSONObject): JSONObject {
    const preparedData = { ...data }

    Object.keys(preparedData).forEach((key) => {
      if (typeof preparedData[key] === 'object' && preparedData[key] !== null) {
        preparedData[key] = JSON.stringify(preparedData[key])
      }
    })

    return preparedData
  }

  /**
   * Initializes the placeholder values for the form.
   * @private
   */
  private initializePlaceholderValues() {
    this.predefinedVariables = this.editorStateService.predefinedPlaceholderVariables
    this.placeholders = this.editorStateService.placeholderVariablesWithValue
    this.addPlaceholdersToForm(this.placeholders)
  }

  /**
   * Adds placeholders to the form as form controls.
   * @param placeholders - List of placeholders to be added.
   */
  private addPlaceholdersToForm(placeholders: WsEmailPlaceholderVariable[]): void {
    placeholders.forEach((placeholder) => {
      this.testDataFormGroup.addControl(placeholder.identifier, new FormControl<JSONValue>(placeholder.value || ''))
      if (placeholder.isCustom) {
        this.testDataFormGroup.addControl(`${placeholder.identifier}__key`, new FormControl(placeholder.identifier))
      }
    })
  }

  /**
   * Prepares the data for saving by processing placeholders and custom fields.
   * Ensures objects are correctly formatted and not over-stringified.
   * @returns The processed data ready to be saved.
   */
  private prepareDataForSave(): JSONObject {
    const data: JSONObject = { ...this.testDataFormGroup.value }

    this.placeholders.forEach((placeholder) => this.processPlaceholderForSave(placeholder, data))

    return data
  }

  /**
   * Processes each placeholder, handling both predefined and custom fields for saving.
   * @param placeholder - The placeholder being processed.
   * @param data - The data object to populate with processed values.
   */
  private processPlaceholderForSave(placeholder: WsEmailPlaceholderVariable, data: JSONObject): void {
    let value = this.testDataFormGroup.get(placeholder.identifier)?.value

    if (typeof value === 'string') {
      try {
        value = JSON.parse(value)
      } catch (e) {
        // If it can't be parsed, keep the string as-is
      }
    }

    if (value) {
      data[placeholder.identifier] = value
    } else {
      this.cleanUpUnusedFields(placeholder.identifier, data)
    }

    if (placeholder.isCustom) {
      this.handleCustomField(placeholder.identifier, value, data)
    }
  }

  /**
   * Handles saving custom fields by updating the data with the custom key.
   * @param identifier - The identifier of the custom field.
   * @param value - The value of the custom field.
   * @param data - The data object to update.
   */
  private handleCustomField(identifier: string, value: string, data: JSONObject): void {
    const key = this.testDataFormGroup.get(`${identifier}__key`)?.value
    if (key) {
      delete data[identifier]
      delete data[`${identifier}__key`]
      data[key] = value
    }
  }

  /**
   * Cleans up unused fields from the data.
   * @param identifier - The identifier to remove.
   * @param data - The data object to clean.
   */
  private cleanUpUnusedFields(identifier: string, data: JSONObject): void {
    delete data[identifier]
    delete data[`${identifier}__key`]
  }
}
