@if (ngControl) {
  <mat-form-field [floatLabel]="floatLabel" class="w-full" [class.hide-subscript]="!hint && !hasErrors">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    @if (filter && !platformService.isMobile && panelOpen) {
      <ng-container [ngTemplateOutlet]="filterInputTemplate"></ng-container>
    }
    <mat-select
      #selectElement
      [placeholder]="placeholder"
      [formControl]="formControl"
      (blur)="handleInputBlur()"
      (valueChange)="handleValueChange($event)"
      [multiple]="multiple"
      [compareWith]="compareWithFunction ? compareWithFunction : compareObjects"
      [panelWidth]="panelWidth"
      (openedChange)="onSelectOpenedChange($event); toggleSelectSpeechRecognition(false)"
      (selectionChange)="toggleSelectSpeechRecognition(false)"
    >
      <mat-select-trigger>
        <div class="flex justify-between" [title]="selectedOptionsString">
          <div
            class="overflow-ellipsis overflow-hidden"
            [class.hidden]="filter && !platformService.isMobile && panelOpen"
            #selectedOptions
          >
            @for (
              option of options | selectedOptions: formControl.value : multiple : compareWithFunction;
              track option.value
            ) {
              <!-- lint disable needed here to prevent line break and therefore a space between label and comma -->
              <!-- eslint-disable-next-line -->
              {{ option.label | translate }}@if (!$last) {,}
            }
          </div>

          @if (clearable && formControl.value) {
            <mat-icon class="cursor-pointer" (click)="handleClearIconClick($event)">close</mat-icon>
          }
        </div>
      </mat-select-trigger>

      @if (filter && platformService.isMobile) {
        <ng-container
          [ngTemplateOutlet]="filterInputTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'border-b-2 p-3' }"
        ></ng-container>
      }
      @for (option of options; track $index) {
        <mat-option
          [class]="matOptionClass"
          [disabled]="option.disabled"
          [class.hidden]="matchesFilter(option)"
          [value]="option.value"
        >
          {{ option.label | translate }}
        </mat-option>
      }

      @if (filteredOptions.length === 0) {
        <mat-option disabled>{{ 'ws.forms.select.noResults' | translate }}</mat-option>
      }
    </mat-select>

    @if (hasErrors) {
      <mat-error>
        {{ validationErrorMessage }}
      </mat-error>
    }

    @if (hint) {
      <mat-hint>{{ hint }}</mat-hint>
    }
  </mat-form-field>
}

<ng-template #filterInputTemplate let-inputClass>
  <div class="ws-select_filter-input" [class.ws-select_filter--mobile]="platformService.isMobile">
    <input
      #filterInput
      placeholder="{{ 'ws.forms.select.filter' | translate }}"
      type="text"
      (ngModelChange)="setFilteredOptions($event)"
      [(ngModel)]="search"
      class="w-full outline-0"
      [class]="inputClass"
    />

    @if (speechRecognition && speechRecognitionIcon && speechRecognitionService.speechRecognitionSupported) {
      <mat-icon
        class="ws-select_speech-recognition-icon ws-speech-recognition-icon cursor-pointer"
        [ngClass]="{ recording: listening }"
        (click)="toggleSpeechRecognition()"
        (touchstart)="touchStartSpeechRecognition()"
        (touchend)="touchStopSpeechRecognition()"
        >{{ speechRecognitionIcon }}
      </mat-icon>
    }
  </div>
</ng-template>
