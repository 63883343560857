import { inject, Injectable } from '@angular/core'
import { SwUpdate } from '@angular/service-worker'

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private swUpdate: SwUpdate = inject(SwUpdate)

  public oldVersionNoticeVisible = false

  public async applyUpdateAndReloadPage(): Promise<boolean> {
    // call to activateUpdate() is not necessary in all browsers if we reload the after an update was found
    // we do it nonetheless to ensure the app is updated
    return this.swUpdate
      .activateUpdate()
      .then((updateSuccessfullyActivated: boolean) => {
        this.oldVersionNoticeVisible = false
        location.reload()
        return updateSuccessfullyActivated
      })
      .catch((e) => {
        console.log('failed to activate update', e)
        return false
      })
  }
}
