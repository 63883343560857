import { Translations } from '@ws-core/core-library'
import { JSONValue } from './ws-json'

export class WsEmailPlaceholderVariable {
  identifier!: string
  value?: JSONValue
  label?: Translations = {}
  type?: PlaceholderType = PlaceholderTypes.TEXT
  isCustom: boolean = false
}

export const PlaceholderTypes = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  URL: 'URL',
  COLOR: 'COLOR',
  SOCIAL_NETWORK_LINK: 'SOCIAL_NETWORK_LINK'
} as const
export type PlaceholderType = (typeof PlaceholderTypes)[keyof typeof PlaceholderTypes]
