import { Component, inject, Input, ViewChild } from '@angular/core'
import { Editor, MenuComponent, Toolbar, TOOLBAR_MINIMAL } from 'ngx-editor'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { PlaceholderVariableSelectionComponent } from '../variable-selection/placeholder-variable-selection.component'
import { WsEmailPlaceholderVariable } from '../../../../../../../email/src/app/data/types/ws-email-placeholder-variable'
import { ColorPickerComponent } from '../../color-picker/color-picker.component'

@Component({
  selector: 'ws-form-text-editor-menu',
  templateUrl: './custom-editor-menu.component.html',
  styleUrls: ['./custom-editor-menu.component.scss']
})
export class CustomEditorMenuComponent {
  @Input() showEmojiPicker = true
  @Input() showTextColorPicker = true
  @Input() editor: Editor | undefined
  @Input() toolbar: Toolbar = TOOLBAR_MINIMAL
  @Input() placeholderVariables: WsEmailPlaceholderVariable[] = []

  // placeholderVariablesColors: string[] = []

  colorSelected = ''

  @ViewChild('editorMenu', { static: false }) editorMenu: MenuComponent | undefined
  @ViewChild('textColorPicker', { static: false }) textColorPicker: ColorPickerComponent | undefined

  private dialog: MatDialog = inject(MatDialog)

  // ngOnInit(): void {
  //   this.placeholderVariablesColors = this.placeholderVariables
  //     .filter(
  //       (placeholderVariable: WsEmailPlaceholderVariable) =>
  //         placeholderVariable.type === PlaceholderTypes.COLOR && placeholderVariable.value
  //     )
  //     .map((placeholderVariable: WsEmailPlaceholderVariable) => placeholderVariable.value?.toString() || '')
  // }

  applyTextColor() {
    this.editor?.commands.textColor(this.colorSelected).exec()
  }

  insertText(emoji: string) {
    this.editor?.commands.insertText(emoji).exec()
  }

  protected handleVariableSelectionClicked() {
    const responseHandler = (placeholderVariable: WsEmailPlaceholderVariable) => {
      const textToInsert = '${' + placeholderVariable.identifier + '}'
      return this.insertText(textToInsert)
    }
    this.openInsertPlaceholderDialog(() => true, responseHandler)
  }

  // Todo: find possiblity to implement placeholders in text editor for colors
  // protected handleVariableSelectionColorPickerClicked() {
  //   const responseHandler = (placeholder: WsEmailPlaceholderVariable) => {
  //     this.colorSelected = placeholder.value?.toString() || ''
  //     this.applyTextColor()
  //     if (this.textColorPicker) {
  //       this.textColorPicker.value = this.colorSelected
  //       this.textColorPicker.openColorPicker()
  //     }
  //   }
  //   const colorFilter = (placeholderVar: WsEmailPlaceholderVariable) => placeholderVar.type === PlaceholderTypes.COLOR
  //   this.openInsertPlaceholderDialog(colorFilter, responseHandler)
  // }

  /**
   * Opens the dialog to insert a placeholder variable
   * @private
   */
  private openInsertPlaceholderDialog(
    placeholderFilter: (placeholderVar: WsEmailPlaceholderVariable) => boolean,
    responseHandler: (response: WsEmailPlaceholderVariable) => void
  ) {
    const editTestDataDialogConfig = new MatDialogConfig()

    editTestDataDialogConfig.disableClose = true
    editTestDataDialogConfig.autoFocus = true
    editTestDataDialogConfig.minWidth = '30vw'

    editTestDataDialogConfig.data = this.placeholderVariables.filter(placeholderFilter)
    const editTestDataDialogRef = this.dialog.open(PlaceholderVariableSelectionComponent, editTestDataDialogConfig)

    editTestDataDialogRef.afterClosed().subscribe((response: WsEmailPlaceholderVariable) => {
      if (response) {
        responseHandler(response)
      }
    })
  }

  /**
   * Opens Color Picker
   * @protected
   */
  protected openColorPicker() {
    this.colorSelected = ''
    if (this.textColorPicker) {
      const selection = window.getSelection()
      if (selection && selection.rangeCount > 0) {
        const selectedElement = selection.anchorNode?.parentElement

        if (selectedElement?.tagName === 'SPAN') {
          // Get the computed color of the span
          const computedStyle = window.getComputedStyle(selectedElement)
          if (computedStyle.color && computedStyle.color.startsWith('rgb')) {
            this.colorSelected = '#' + this.textColorPicker.rgbToHex(computedStyle.color).toUpperCase()
            this.textColorPicker.value = this.colorSelected
          } else {
            this.colorSelected = computedStyle.color
            this.textColorPicker.value = this.colorSelected
          }
        }
      }
      this.textColorPicker.openColorPicker()
    }
  }
}
