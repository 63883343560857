import { Component, inject, Inject } from '@angular/core'
import { LocalizedPipe, SelectOption } from '@ws-core/core-library'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { WsEmailPlaceholderVariable } from '../../../../../../../email/src/app/data/types/ws-email-placeholder-variable'

@Component({
  selector: 'ws-placeholder-variable-selection',
  templateUrl: './placeholder-variable-selection.component.html',
  styleUrl: './placeholder-variable-selection.component.scss'
})
export class PlaceholderVariableSelectionComponent {
  protected selectedPlaceholderVariable: WsEmailPlaceholderVariable | undefined
  protected placeholderSelectOptions: SelectOption[] = []

  private localizedPipe = inject(LocalizedPipe)

  constructor(
    private dialogRef: MatDialogRef<PlaceholderVariableSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WsEmailPlaceholderVariable[]
  ) {
    this.setPlaceholderSelectOptions()
  }

  /**
   * Sets the placeholder select options
   */
  private setPlaceholderSelectOptions() {
    this.placeholderSelectOptions = this.data.map((placeholderVariable: WsEmailPlaceholderVariable) => {
      return {
        label: this.localizedPipe.transform(placeholderVariable.label),
        value: placeholderVariable
      }
    })
  }

  /**
   * Closes the dialog
   * @protected
   */
  protected close(): void {
    this.dialogRef.close()
  }

  /**
   * Close the dialog and return the selected placeholder variable
   * @protected
   */
  protected insertVariable() {
    this.dialogRef.close(this.selectedPlaceholderVariable)
  }
}
