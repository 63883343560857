import mjml2html from 'mjml-browser'
import { StyleAttributes, TestData } from './brick.types'

export abstract class Brick {
  id = '0'
  name = ''
  readonly type: string
  content: string
  styles: StyleAttributes
  mjmlTag: string
  canHaveChildren = false
  html: string = ''
  htmlWithTestData: string = ''
  mjml: string = ''

  readonly children: Brick[] = []

  constructor(type = '', content = '', styles: StyleAttributes = {}, mjmlTag = '', name = '') {
    this.type = type
    this.id = this.generateId()
    this.content = content
    this.styles = styles
    this.mjmlTag = mjmlTag
    this.name = name
  }

  isHiddenMobile = () => !!this.styles['css-class']?.toString()?.includes('hide-mobile')

  isHidden = () => !!this.styles['css-class']?.toString()?.includes('hidden-element')

  generateId() {
    return `${this.type}-${Math.floor(Math.random() * 1000000).toString()}`
  }

  addDefaultStyles(defaultStyles: StyleAttributes) {
    Object.keys(defaultStyles).forEach((key) => {
      if ((this.styles[key] === undefined || this.styles[key] === '') && defaultStyles[key] !== '') {
        this.styles[key] = defaultStyles[key]
      }
    })
  }

  hasGutter() {
    return false
  }

  getStyles(testData: TestData = {}): any {
    return this.styles
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  add(brick: Brick): void {
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  remove(brick: Brick) {
    return
  }

  getChildren(): Brick[] {
    return []
  }

  setChildren(children: Brick[]) {
    return
  }

  getVisibleChildren(): Brick[] {
    return []
  }

  getMjmlWithGlobalStyles(globalStyles: StyleAttributes = {}) {
    // overwritten in only in text class
    return this.getMjml()
  }

  getHtmlWithGlobalStyles(globalStyles: StyleAttributes = {}, testData: TestData = {}): string {
    const componentMjml = `<mjml><mj-head <mj-style inline="inline">img {object-fit: contain;}</mj-style></mj-head><mj-body>${this.getMjmlWithGlobalStyles(globalStyles)}</mj-body></mjml>`
    this.html = mjml2html(componentMjml).html
    return this.html
  }

  getHtmlWithTestData(testData: TestData, globalStyles: StyleAttributes = {}): string {
    this.htmlWithTestData = this.replaceTestData(this.getHtmlWithGlobalStyles(globalStyles), testData) || ''
    return this.htmlWithTestData
  }

  getHtml() {
    const componentMjml = `<mjml><mj-head></mj-head><mj-body>${this.getMjml()}</mj-body></mjml>`
    this.html = mjml2html(componentMjml).html
    return this.html
  }

  getMjml(withoutHiddenBricks = false): string {
    if (this.isHidden() && withoutHiddenBricks) return ''

    const styles = Object.keys(this.styles)
      .map((style) => ` ${style}="${this.styles[style]}"`)
      .join('')
    this.mjml = `<${this.mjmlTag}${styles}>${this.content}</${this.mjmlTag}>`
    return this.mjml
  }

  getPadding(): string {
    const paddingStyles = this.styles['padding']
    let padding = paddingStyles ? paddingStyles.toString().split(' ') : []

    switch (padding.length) {
      case 1:
        padding = [padding[0], padding[0], padding[0], padding[0]]
        break
      case 2:
        padding = [padding[0], padding[1], padding[0], padding[1]]
        break
      case 3:
        padding = [padding[0], padding[1], padding[2], padding[1]]
        break
      case 4:
        padding = [padding[0], padding[1], padding[2], padding[3]]
        break
      default:
        padding = ['0', '0', '0', '0']
    }

    const paddingTop = this.styles['padding-top'] !== undefined ? this.styles['padding-top'] : padding[0]
    const paddingRight = this.styles['padding-right'] !== undefined ? this.styles['padding-right'] : padding[1]
    const paddingBottom = this.styles['padding-bottom'] !== undefined ? this.styles['padding-bottom'] : padding[2]
    const paddingLeft = this.styles['padding-left'] !== undefined ? this.styles['padding-left'] : padding[3]

    return `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`
  }

  getContentWidth() {
    return this.styles['width']
  }

  widthParser(width: any, options: any = {}) {
    const unitRegex = /(\d*\.?\d+)\s*([a-zA-Z%]*)/
    // Destructure the options object with a default value for parseFloatToInt
    const { parseFloatToInt = true } = options

    // Use a regular expression to extract the unit from the width string
    const widthUnitArray = unitRegex.exec(width)
    let widthUnit: string = ''
    if (widthUnitArray && widthUnitArray.length == 3) {
      widthUnit = widthUnitArray[2]
    }

    // Define a set of unit parsers for different units
    const unitParsers: any = {
      default: parseInt, // Default parser if the unit is not recognized
      px: parseInt, // Parser for pixels
      '%': parseFloatToInt ? parseInt : parseFloat // Parser for percentage
    }

    // Get the parser based on the unit or use the default parser
    const parser = unitParsers[widthUnit] || unitParsers.default

    // Return an object with the parsed width and the unit
    return {
      parsedWidth: parser(width),
      unit: widthUnit || 'px' // Default to 'px' if the unit is not specified
    }
  }

  hasBackground() {
    return !!this.styles['background-url']
  }

  getBackground(testData: TestData = {}) {
    const imageUrl = `url("${this.replaceTestData(this.styles['background-url']?.toString() || '', testData)}")`
    const repeatValue = this.styles['background-repeat'] || ''
    const positionValue = this.styles['background-position'] || ''
    const sizeValue = this.styles['background-size'] || ''
    const backgroundColorValue = this.replaceTestData(this.styles['background-color']?.toString() || '', testData)

    return `${imageUrl}  ${positionValue} / ${sizeValue} ${repeatValue} ${backgroundColorValue}`
  }

  isFullWidth() {
    return this.styles['full-width'] === 'full-width'
  }

  renewIds() {
    this.id = this.generateId()
  }

  setInvisible() {
    if (this.styles['css-class'] === 'undefined') {
      this.styles['css-class'] = ''
    }
    if (this.styles['css-class']) {
      this.styles['css-class'] = this.styles['css-class'] + ' hidden-element'
    } else {
      this.styles['css-class'] += 'hidden-element'
    }
  }

  setVisible() {
    if (this.styles['css-class']) {
      this.styles['css-class'] = this.styles['css-class']
        .toString()
        .replace(' hidden-element', '')
        .replace('hidden-element', '')
    }
    if (!this.styles['css-class'] || this.styles['css-class'] === 'undefined') {
      delete this.styles['css-class']
    }
  }

  private flattenJson(data: any, parentKey: string = '', result: any = {}): any {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key
        if (typeof data[key] === 'object' && data[key] !== null) {
          this.flattenJson(data[key], newKey, result)
        } else {
          result[newKey] = data[key]
        }
      }
    }
    return result
  }

  // replaceValueWithTestDataIdentifier(value: string, testData: TestData) {
  //   Object.keys(testData).forEach((key) => {
  //     if (key.includes('color')) {
  //       value = value.replaceAll(testData[key], '${' + key + '}')
  //
  //       const rgb = this.hexToRgb(testData[key])?.toString() || ''
  //       if (rgb) {
  //         value = value.replaceAll(rgb, '${' + key + '}')
  //       }
  //     }
  //   })
  //
  //   return value
  // }
  //
  // hexToRgb(hex: string): string | null {
  //   // Remove the hash if present
  //   hex = hex.replace(/^#/, '')
  //
  //   // Check for valid 3 or 6 character HEX color
  //   if (hex.length === 3) {
  //     // Expand shorthand notation to full form, e.g., "abc" -> "aabbcc"
  //     hex = hex
  //       .split('')
  //       .map((char) => char + char)
  //       .join('')
  //   }
  //
  //   if (hex.length !== 6) {
  //     return null // Invalid HEX format
  //   }
  //
  //   // Parse the hex string into RGB components
  //   const r = parseInt(hex.substring(0, 2), 16)
  //   const g = parseInt(hex.substring(2, 4), 16)
  //   const b = parseInt(hex.substring(4, 6), 16)
  //
  //   return `rgb(${r}, ${g}, ${b})`
  // }

  replaceTestData(content: string, testData: TestData) {
    // Flatten the testDataJson to handle nested keys
    const flatData = this.flattenJson(testData)

    // Perform the replacements using the flattened keys
    Object.keys(flatData).forEach((key) => {
      // Create regex to match {key} but not ${key}
      const regex2 = new RegExp('(?<!\\$)\\{' + key + '+\\}', 'g')
      content = content.replace(regex2, flatData[key])

      const regex = new RegExp('\\$\\{' + key + '\\}', 'g')
      content = content.replace(regex, flatData[key])
    })

    return content
  }

  /**
   * Converts variables from the format (((variable))) to ${variable}.
   * @param content - The string containing variables in the format (((variable))).
   * @returns The string with variables converted to ${variable} format.
   */
  convertTripleParenthesesToPlaceholderFormat(content: string): string {
    // Regex pattern to match (((variable))) formats
    const pattern = /\(\(\(([^)]+)\)\)\)/g

    // Replacement function to format the variable as ${variable}
    const replacement = (_match: string, p1: string) => `\${${p1}}`

    // Perform the replacement
    return content.replace(pattern, replacement)
  }

  /**
   * Converts variables from the format ${variable} to (((variable))).
   * @param content - The string containing variables in the format ${variable}.
   * @returns The string with variables converted to (((variable))) format.
   */
  convertPlaceholderToTripleParenthesesFormat(content: string): string {
    // Regex pattern to match ${variable} formats
    const pattern = /\$\{([a-zA-Z0-9_.]+)}/g

    // Replacement function to format the variable as (((variable)))
    const replacement = (_match: string, p1: string) => `(((${p1})))`

    // Perform the replacement
    return content.replace(pattern, replacement)
  }

  replaceTestDataOfStyles(styles: StyleAttributes, testData: TestData): StyleAttributes {
    Object.keys(styles).forEach((style) => {
      styles[style] = this.replaceTestData(styles[style]?.toString() || '', testData)
    })
    return styles
  }

  get someChildrenHasWidth() {
    return this.getChildren().some((col) => col.styles['width'])
  }
}
